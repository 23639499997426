import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Button,
  FormControl,
  Divider,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SaveIcon from "@mui/icons-material/Save";
import { UploadFile } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { RiDeleteBinLine } from "react-icons/ri";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

import {
  createForm301,
  getForm301ById,
  getForm301DataById,
  updateForm301,
} from "../../../redux/slices/form300.slice";
import { setSnackbar } from "../../../redux/slices/common.slice";
import moment from "moment";
import Loader from "../Loader";
import axios from "axios";
const Form301 = ({ mode }) => {
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = React.useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { form301Id } = useParams();
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    setEmployeeData((prevState) => {
      const updatedEmployees = [...prevState];
      updatedEmployees[index] = {
        ...updatedEmployees[index],
        [name]: value,
      };
      return updatedEmployees;
    });
  };

  const handleDateChange = (field, date, index) => {
    console.log(field, date);
    setEmployeeData((prevState) => {
      const updatedEmployees = [...prevState];
      updatedEmployees[index] = {
        ...updatedEmployees[index],
        [field]: moment(date).format("YYYY-MM-DD"),
      };
      return updatedEmployees;
    });
  };
  const handleTimeChange = (field, date, index) => {
    console.log(field, date);
    setEmployeeData((prevState) => {
      const updatedEmployees = [...prevState];
      updatedEmployees[index] = {
        ...updatedEmployees[index],
        [field]: date,
      };
      return updatedEmployees;
    });
  };

  const [expanded, setExpanded] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchForm301Data = async () => {
    setIsLoading(true);
    const selectedId = JSON.parse(localStorage.getItem("selectedReport301"));
    console.log(selectedId);
    let response = await dispatch(
      getForm301DataById({ report_id: selectedId })
    );
    const { payload } = response;
    if (payload?.status) {
      const Form301Data = payload?.data?.employees?.map((emp) => ({
        title: payload?.data?.user?.title,
        phone: payload?.data?.user?.phone,
        date: payload?.data?.user?.date,
        completedBy: payload?.data?.user?.completed_by,
        fullName: emp?.full_name,
        street: emp?.street || "",
        city: emp?.city || "",
        state: emp?.state || "",
        Zip: emp?.zip_code || "",
        dob: emp?.date_of_birth || null,
        dateHired: emp?.date_hired || null,
        gender: emp?.gender || "Male",
        physicianName: "",
        facility: "",
        treatmentStreet: "",
        treatmentCity: "",
        treatmentState: "",
        treatmentZip: "",
        treatedInEmergencyRoom: false,
        hospitalizedOvernight: false,
        caseNumber: "",
        dateOfInjury: null,
        employeeBeganWork: null,
        timeOfEvent: "2022-04-17T15:32",
        whatWasEmployeeDoing: "",
        whatHappened: "",
        injuryOrIllness: "",
        objectOrSubstance: "",
        dateOfDeath: null,
      }));

      setEmployeeData(Form301Data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
    setIsLoading(false);
  };

  const fetchForm301DataById = async () => {
    setIsLoading(true);

    let response = await dispatch(getForm301ById({ form301Id }));
    const { payload } = response;
    // const payload = {
    //   status: true,
    //   message: "Fetch",
    //   data: {
    //     id: 3,
    //     title: "test-301",
    //     form_link:
    //       "https://pri42291sahndev.blob.core.windows.net/tenant-151-safetycheck/form301/396bc7b6-8bf9-4a37-bfde-cd6eab149d2d.pdf",
    //     form301_json: [
    //       {
    //         title: "On Site Engineer",
    //         phone: "7990554541",
    //         date: "01/02/2024",
    //         completedBy: "jaydev",
    //         fullName: "John Doe",
    //         street: "123 Elm St",
    //         city: "Springfield",
    //         state: "IL",
    //         zip: "62704",
    //         dob: "1985-04-12",
    //         dateHired: "2010-06-15",
    //         gender: "Male",
    //         physicianName: "Dr. John Smith",
    //         facility: "Methodist Hospital",
    //         treatmentStreet: "7777 Forest Ln",
    //         treatmentCity: "San Antonio",
    //         treatmentState: "Texas",
    //         treatmentZip: "75230",
    //         treatedInEmergencyRoom: false,
    //         hospitalizedOvernight: true,
    //         caseNumber: "01",
    //         dateOfInjury: "2024-08-22",
    //         employeeBeganWork: "2022-04-17T15:30",
    //         timeOfEvent: "2022-04-17T15:32",
    //         whatWasEmployeeDoing: "",
    //         whatHappened: "",
    //         injuryOrIllness: "",
    //         objectOrSubstance: "",
    //         dateOfDeath: null,
    //       },
    //       {
    //         title: "Onsite Engineer",
    //         phone: "7990554541",
    //         date: "01/02/2024",
    //         completedBy: "jaydev",
    //         fullName: "John Doe",
    //         street: "123 Elm St",
    //         city: "Springfield",
    //         state: "IL",
    //         zip: "62704",
    //         dob: "1985-04-12",
    //         dateHired: "2010-06-15",
    //         gender: "Male",
    //         physicianName: "Dr. John Smith",
    //         facility: "Methodist Hospital",
    //         treatmentStreet: "7777 Forest Ln",
    //         treatmentCity: "San Antonio",
    //         treatmentState: "Texas",
    //         treatmentZip: "75230",
    //         treatedInEmergencyRoom: false,
    //         hospitalizedOvernight: false,
    //         caseNumber: "02",
    //         dateOfInjury: "2024-08-22",
    //         employeeBeganWork: "2022-04-17T15:30",
    //         timeOfEvent: "2022-04-17T15:32",
    //         whatWasEmployeeDoing: "Working at great height",
    //         whatHappened: "Fall Damage",
    //         injuryOrIllness: "broken leg",
    //         objectOrSubstance: "Ground",
    //         dateOfDeath: null,
    //       },
    //     ],
    //     description: "test_description",
    //     created_at: "2024-09-18T12:11:11.154638Z",
    //     updated_at: "2024-09-18T12:11:11.155141Z",
    //     created_by: 90,
    //   },
    // };
    if (payload?.status) {
      setEmployeeData(payload?.data?.form301_json);
      setTitle(payload?.data?.title);
      setDescription(payload?.data?.description);

      //   title: payload?.data?.user?.title,
      //   phone: payload?.data?.user?.phone,
      //   date: payload?.data?.user?.date,
      //   completedBy: payload?.data?.user?.completed_by,
      //   fullName: emp?.full_name,
      //   street: emp?.street || "",
      //   city: emp?.city || "",
      //   state: emp?.state || "",
      //   Zip: emp?.zip_code || "",
      //   dob: emp?.date_of_birth || null,
      //   dateHired: emp?.date_hired || null,
      //   gender: emp?.gender || "Male",
      //   physicianName: "",
      //   facility: "",
      //   treatmentStreet: "",
      //   treatmentCity: "",
      //   treatmentState: "",
      //   treatmentZip: "",
      //   treatedInEmergencyRoom: false,
      //   hospitalizedOvernight: false,
      //   caseNumber: "",
      //   dateOfInjury: null,
      //   employeeBeganWork: null,
      //   timeOfEvent: "2022-04-17T15:32",
      //   whatWasEmployeeDoing: "",
      //   whatHappened: "",
      //   injuryOrIllness: "",
      //   objectOrSubstance: "",
      //   dateOfDeath: null,
      // }));

      // setEmployeeData(Form301Data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (mode === "create") fetchForm301Data();
    else {
      fetchForm301DataById();
    }
  }, []);

  const deleteForm301 = (i) => {
    let tempData = [...employeeData];
    tempData.splice(i, 1);
    setEmployeeData(tempData);
  };
  // function download(content, mimeType, filename) {
  //   const a = window.document.createElement("a"); // Create "a" element
  //   const blob = new Blob([content], { type: mimeType }); // Create a blob (file-like object)
  //   const url = URL.createObjectURL(blob); // Create an object URL from blob
  //   a.setAttribute("href", url); // Set "a" element link
  //   a.setAttribute("download", filename); // Set download filename
  //   a.click(); // Start downloading
  // }
  const handleSubmit = async (key) => {
    if (!title) {
      setTitleError("Title is Required");
      return;
    }
    if (!description) {
      setDescriptionError("Description is Required");
      return;
    }
    setIsPDFLoading(true);

    try {
      let response;
      if (mode === "create") {
        response = await dispatch(
          createForm301({
            data: employeeData,
            title: title,
            description: description,
            key,
          })
        );
      } else {
        response = await dispatch(
          updateForm301({
            data: employeeData,
            title: title,
            description: description,
            key,
            form301Id: form301Id,
          })
        );
      }

      const { payload } = response;

      if (payload && payload.status) {
        if (key === true) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "Form301 Downloaded Succesfully.",
            })
          );
          setIsPDFLoading(false);

          // saveAs(
          //   `${payload.data.form_link}?${payload.data.sas_token}`,
          //   "example.pdf"
          // );

          // axios({
          //   method: "get",
          //   url: `${payload.data.form_link}?${payload.data.sas_token}`,
          //   transformResponse: [
          //     function (data) {
          //       return data;
          //     },
          //   ],
          // }).then((response) => {
          //   console.log(response);
          //   download(
          //     response.data,
          //     response.headers["content-type"],
          //     "abc-dummy.json"
          //   );
          // });

          const link = document.createElement("a");

          link.href = `${payload.data.form_link}?${payload.data.sas_token}`;
          link.target = "_blank";
          link.download = "abc.pdf"; // Set the filename (you might want to get this from the backend)
          link.setAttribute("download", "abc.pdf");
          document.body.appendChild(link);
          link.click();

          // Clean up
          link.parentNode.removeChild(link);
          navigate("/reports/form301");
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message:
                mode === "create"
                  ? "Form301 created Succesfully."
                  : "Form301 updated Succesfully.",
            })
          );
          navigate("/reports/form301");
          setIsPDFLoading(false);
        }
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
        setIsPDFLoading(false);
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
      setIsPDFLoading(false);
    }
  };
  return (
    <>
      {isPDFLoading && <Loader />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="action-btns"
        mb={2}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            navigate(-1);
          }}
          startIcon={<ArrowBackIcon />}
          className="primary-button back-button"
        >
          Back
        </Button>
        <Box
          sx={{ display: "flex", gap: "20px", justifyContent: "flex-end" }}
          className="inner-action-btns"
        >
          {mode !== "view" && (
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<SaveIcon />}
              disableRipple
              onClick={() => handleSubmit(false)}
            >
              Save
            </Button>
          )}

          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<UploadFile />}
            disableRipple
            onClick={() => handleSubmit(true)}
          >
            Export PDF
          </Button>
        </Box>
      </Box>
      <Box className="form301-container">
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              height: "300px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <>
            <Grid container spacing={2} mt={2} mb={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Typography variant="body1" component="label">
                  Title
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setTitleError("");
                  }}
                  disabled={mode === "view"}
                  sx={{
                    borderColor: "#64bcd2",
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#64bcd2" },
                    },
                  }}
                />
                {titleError && <div className="error-msg">{titleError}</div>}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Typography variant="body1" component="label">
                  Description
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  value={description}
                  size="small"
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionError("");
                  }}
                  disabled={mode === "view"}
                  sx={{
                    borderColor: "#64bcd2",
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#64bcd2" },
                    },
                  }}
                />
                {descriptionError && (
                  <div className="error-msg">{descriptionError}</div>
                )}
              </Grid>
            </Grid>
            {employeeData.map((employee, index) => (
              <Box
                sx={{
                  display: "flex",

                  gap: "20px",
                  marginBottom: "16px", // Add space between accordions
                }}
              >
                <Accordion
                  key={employee.id}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                  sx={{
                    "& .MuiAccordionSummary-root": {
                      backgroundColor: expanded === index ? "#64bcd2" : "white", // Apply background color when expanded
                      color:
                        expanded === index
                          ? "white !important"
                          : "#036185 !important", // Text color when expanded, header color when not expanded
                      "& .MuiTypography-root": {
                        fontSize: "16px",
                      },
                      border: "1px solid #64bcd2", // Border to make header stand out
                      height: "56px !important",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: expanded === index ? "white" : "#64bcd2",
                        }}
                      />
                    }
                    aria-controls={`${index}-content`}
                    id={`${index}-header`}
                  >
                    <Typography variant="h6">{employee.fullName}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        margin: "auto",
                        padding: "2rem",
                        maxHeight: "560px",
                        overflowY: "auto",
                      }}
                      className="table-container"
                    >
                      <Grid
                        container
                        spacing={4}
                        className={mode === "view" ? "form-disabled" : ""}
                      >
                        {/* Information About the Employee */}
                        <Grid item xs={12} md={3}>
                          <FormLabel>Title</FormLabel>
                          <TextField
                            fullWidth
                            name="title"
                            value={employee.title}
                            onChange={(e) => handleInputChange(e, index)}
                            variant="standard"
                            sx={{
                              marginBottom: "1rem",
                              "& .MuiInputBase-root": {
                                height: "40px", // Adjust field height
                                "& input": {
                                  padding: "4px 10px", // Adjust padding
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormLabel>Completed By</FormLabel>
                          <TextField
                            fullWidth
                            name="completedBy"
                            value={employee.completedBy}
                            onChange={(e) => handleInputChange(e, index)}
                            variant="standard"
                            sx={{
                              marginBottom: "1rem",
                              "& .MuiInputBase-root": {
                                height: "40px", // Adjust field height
                                "& input": {
                                  padding: "4px 10px", // Adjust padding
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormLabel>Phone</FormLabel>
                          <TextField
                            fullWidth
                            name="phone"
                            value={employee.phone}
                            onChange={(e) => handleInputChange(e, index)}
                            variant="standard"
                            sx={{
                              marginBottom: "1rem",
                              "& .MuiInputBase-root": {
                                height: "40px", // Adjust field height
                                "& input": {
                                  padding: "4px 10px", // Adjust padding
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormLabel>Date</FormLabel>
                          <DatePicker
                            selected={employee.date}
                            onChange={(date) =>
                              handleDateChange("date", date, index)
                            }
                            customInput={
                              <TextField
                                fullWidth
                                variant="standard"
                                placeholder="Date"
                                sx={{
                                  height: "40px",
                                  marginBottom: "1rem",
                                  "& input": {
                                    padding: "4px 10px", // Adjust padding
                                  },
                                  "& div": {
                                    height: "100%",
                                  },
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CalendarTodayIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            }
                            className="react-datepicker-wrapper"
                          />
                        </Grid>

                        <Grid item xs={12} md={5.7} direction="column">
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <Typography
                                variant="h6"
                                sx={{
                                  marginBottom: "1rem",
                                  color: "var(--color-theme-secondary)",
                                }}
                              >
                                Information about the Employee
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <FormLabel>Full Name</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="fullName"
                                    value={employee.fullName}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      marginBottom: "1rem",
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormLabel>Street</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="street"
                                    value={employee.street}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <FormLabel>City</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="city"
                                    value={employee.city}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      marginBottom: "1rem",
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <FormLabel>State</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="state"
                                    value={employee.state}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      marginBottom: "1rem",
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <FormLabel>Zip Code</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="Zip"
                                    value={employee.Zip}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      marginBottom: "1rem",
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormLabel>Date of Birth</FormLabel>
                                  <DatePicker
                                    selected={employee.dob}
                                    onChange={(date) =>
                                      handleDateChange("dob", date, index)
                                    }
                                    customInput={
                                      <TextField
                                        fullWidth
                                        variant="standard"
                                        placeholder="Date of Birth"
                                        sx={{
                                          height: "40px",
                                          marginBottom: "1rem",
                                          "& input": {
                                            padding: "4px 10px", // Adjust padding
                                          },
                                          "& div": {
                                            height: "100%",
                                          },
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <CalendarTodayIcon />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    }
                                    className="react-datepicker-wrapper"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormLabel>Date Hired</FormLabel>
                                  <DatePicker
                                    selected={employee.dateHired}
                                    onChange={(date) =>
                                      handleDateChange("dateHired", date, index)
                                    }
                                    customInput={
                                      <TextField
                                        fullWidth
                                        variant="standard"
                                        placeholder="Date Hired"
                                        s
                                        sx={{
                                          height: "40px",
                                          marginBottom: "1rem",
                                          "& input": {
                                            padding: "4px 10px", // Adjust padding
                                          },
                                          "& div": {
                                            height: "100%",
                                          },
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <CalendarTodayIcon />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    }
                                    className="react-datepicker-wrapper"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormLabel>Gender</FormLabel>
                                  <RadioGroup
                                    name="gender"
                                    value={employee.gender}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    row
                                  >
                                    <FormControlLabel
                                      value="Male"
                                      control={<Radio />}
                                      label="Male"
                                    />
                                    <FormControlLabel
                                      value="Female"
                                      control={<Radio />}
                                      label="Female"
                                    />
                                  </RadioGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider
                                sx={{
                                  borderTopWidth: 1,
                                  borderBottomWidth: 1,
                                  width: "100% !important",
                                  height: "1px",
                                  margin: "auto",
                                  color: "var(--color-theme-secondary)",
                                  borderColor: "var(--color-theme-secondary)",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="h6"
                                sx={{
                                  marginBottom: "1rem",
                                  color: "var(--color-theme-secondary)",
                                }}
                              >
                                Information about the physician or other health
                                care professional
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <FormLabel>Name of Physician</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="physicianName"
                                    value={employee.physicianName}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      marginBottom: "1rem",
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormLabel>
                                    If treatment was given away from the
                                    worksite, where was it given?
                                  </FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormLabel>Facility</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="facility"
                                    value={employee.facility}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormLabel>Street</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="treatmentStreet"
                                    value={employee.treatmentStreet}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <FormLabel>City</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="treatmentCity"
                                    value={employee.treatmentCity}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      marginBottom: "2rem",
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <FormLabel>State</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="treatmentState"
                                    value={employee.treatmentState}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      marginBottom: "2rem",
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <FormLabel>Zip Code</FormLabel>
                                  <TextField
                                    fullWidth
                                    name="treatmentZip"
                                    value={employee.treatmentZip}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    variant="standard"
                                    sx={{
                                      marginBottom: "2rem",
                                      "& .MuiInputBase-root": {
                                        height: "40px", // Adjust field height
                                        "& input": {
                                          padding: "4px 10px", // Adjust padding
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl component="fieldset">
                                    <FormLabel>
                                      Was employee treated in an emergency room?
                                    </FormLabel>
                                    <RadioGroup
                                      name="treatedInEmergencyRoom"
                                      value={
                                        employee.treatedInEmergencyRoom
                                          ? "yes"
                                          : "no"
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          {
                                            target: {
                                              name: "treatedInEmergencyRoom",
                                              value: e.target.value === "yes",
                                            },
                                          },
                                          index
                                        )
                                      }
                                      row
                                    >
                                      <FormControlLabel
                                        value="yes"
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="no"
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl component="fieldset">
                                    <FormLabel>
                                      Was employee hospitalized overnight as an
                                      in-patient?
                                    </FormLabel>
                                    <RadioGroup
                                      name="hospitalizedOvernight"
                                      value={
                                        employee.hospitalizedOvernight
                                          ? "yes"
                                          : "no"
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          {
                                            target: {
                                              name: "hospitalizedOvernight",
                                              value: e.target.value === "yes",
                                            },
                                          },
                                          index
                                        )
                                      }
                                      row
                                    >
                                      <FormControlLabel
                                        value="yes"
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="no"
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={0.6}>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              borderRightWidth: 1,
                              borderLeftWidth: 1,
                              height: "100% !important",
                              width: "1px",
                              margin: "auto",
                              color: "var(--color-theme-secondary)",
                              borderColor: "var(--color-theme-secondary)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={5.7}>
                          <Typography
                            variant="h6"
                            sx={{
                              marginBottom: "1rem",
                              color: "var(--color-theme-secondary)",
                            }}
                          >
                            Information about the Case
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FormLabel>Case Number</FormLabel>
                              <TextField
                                fullWidth
                                name="caseNumber"
                                value={employee.caseNumber}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="standard"
                                sx={{
                                  marginBottom: "1rem",
                                  "& .MuiInputBase-root": {
                                    height: "40px", // Adjust field height
                                    "& input": {
                                      padding: "4px 10px", // Adjust padding
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>Date of Injury</FormLabel>
                              <DatePicker
                                selected={employee.dateOfInjury}
                                onChange={(date) =>
                                  handleDateChange("dateOfInjury", date, index)
                                }
                                customInput={
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    placeholder="Date of Injury"
                                    sx={{
                                      height: "40px",
                                      marginBottom: "1rem",
                                      "& input": {
                                        padding: "4px 10px", // Adjust padding
                                      },
                                      "& div": {
                                        height: "100%",
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <CalendarTodayIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                }
                                className="react-datepicker-wrapper"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormLabel>Employee Began Work</FormLabel>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  className="modal-date-picker time-picker"
                                  name="employeBeganWork"
                                  value={dayjs(employee.employeeBeganWork)}
                                  onChange={(time) =>
                                    handleTimeChange(
                                      "employeeBeganWork",
                                      dayjs(time).format("YYYY-MM-DDTHH:mm"),
                                      index
                                    )
                                  }
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                              <FormLabel>Time Of Event</FormLabel>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  className="modal-date-picker time-picker"
                                  name="timeOfEvent"
                                  value={dayjs(employee.timeOfEvent)} // make sure this is initialized correctly
                                  onChange={(time) =>
                                    handleTimeChange(
                                      "timeOfEvent",
                                      dayjs(time).format("YYYY-MM-DDTHH:mm"), // Format to 'YYYY-MM-DDTHH:mm'
                                      index
                                    )
                                  }
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>
                                What was the employee doing just before the
                                incident occurred?
                              </FormLabel>
                              <TextField
                                fullWidth
                                name="whatWasEmployeeDoing"
                                value={employee.whatWasEmployeeDoing}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="outlined"
                                multiline
                                minRows={5}
                                className="form301-textarea"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>
                                What Happened? Tell us how the injury occurred
                              </FormLabel>
                              <TextField
                                fullWidth
                                name="whatHappened"
                                value={employee.whatHappened}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="outlined"
                                multiline
                                minRows={5}
                                className="form301-textarea"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>
                                What was the injury or illness?
                              </FormLabel>
                              <TextField
                                fullWidth
                                name="injuryOrIllness"
                                value={employee.injuryOrIllness}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="outlined"
                                multiline
                                minRows={5}
                                className="form301-textarea"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>
                                {" "}
                                What object or substance directly harmed the
                                employee?
                              </FormLabel>
                              <TextField
                                fullWidth
                                name="objectOrSubstance"
                                value={employee.objectOrSubstance}
                                onChange={(e) => handleInputChange(e, index)}
                                variant="outlined"
                                multiline
                                minRows={4}
                                sx={{ marginBottom: "1rem" }}
                                className="form301-textarea"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel>
                                Date of Death (if Employee Died)
                              </FormLabel>
                              <DatePicker
                                selected={employee.dateOfDeath}
                                onChange={(date) =>
                                  handleDateChange("dateOfDeath", date, index)
                                }
                                customInput={
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    placeholder="Date of Death"
                                    sx={{
                                      height: "40px",
                                      marginBottom: "1rem",
                                      "& input": {
                                        padding: "4px 10px", // Adjust padding
                                      },
                                      "& div": {
                                        height: "100%",
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <CalendarTodayIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                }
                                className="react-datepicker-wrapper"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {mode !== "view" && (
                  <IconButton
                    onClick={() => deleteForm301(index)}
                    sx={{
                      backgroundColor: "#64bcd2",
                      color: "#fff",
                      padding: "10px",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#4a9bb3",
                        color: "#fff",
                      },
                      width: "46px !important",
                      height: "46px !important",
                      position: "relative",
                      top: "6px",
                    }}
                  >
                    <RiDeleteBinLine />
                  </IconButton>
                )}
              </Box>
            ))}
          </>
        )}
      </Box>
    </>
  );
};

export default Form301;
