import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProjectLocationAutoCompleteSuggesstionThunk } from "../../../../redux/slices/tenant.slice";
import CloseIcon from "@mui/icons-material/Cancel";
import FileUpload from "./fileUpload";
import { useDispatch } from "react-redux";
import { getFormById } from "../../../../redux/slices/forms.slice";
import "../../../../styles/forms.css";
export default function FormRenderer({
  formId = null,
  sections = null,
  setFormData,
}) {
  const [locationOption, setLocationOption] = useState([]);

  const [formSections, setFormSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [serviceUnit, setServiceUnit] = useState("Ford Heavy Duty Truck");

  const fetchFormById = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormById({ formId }));
      const { payload } = response;
      if (payload?.status) setFormSections(payload?.data?.form_struct_json);
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    if (formId) fetchFormById();
    else {
      console.log("hello", sections);
      setFormSections(sections);
    }
  }, []);

  let timeOut = null;
  const handleAutocompleteChange = (event, values) => {
    if (values !== "") {
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: values,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  };
  console.log(formSections);
  return (
    <>
      {loading ? (
        <Box
          sx={{
            margin: "auto",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            padding: "24px",
            backgroundColor: "#ffffff",
            border: "1px solid #036185",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <form>
            {formSections &&
              formSections?.map((section, secIndex) => (
                <Accordion key={section.id} defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    className="accordion-summary-background accordion-summary-root"
                  >
                    <Typography className="accordion-summary-typography">
                      {section.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <Grid container spacing={3}>
                      {section.questions.map((question) => (
                        <Grid item xs={question.width}>
                          <Typography variant="body1" component="label">
                            {question.label}
                            {question.required && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </Typography>
                          {question.type === "Text Field" && (
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              placeholder="Your answer here"
                              required={question.required}
                            />
                          )}
                          {question.type === "CheckBox" && (
                            <FormControlLabel
                              control={<Checkbox />}
                              // label={question.label}
                              // labelPlacement="end"
                              sx={{ fontWeight: 600, marginLeft: "10px" }}
                              className="center"
                            />
                          )}
                          {question.type === "Number" && (
                            <TextField
                              type="number"
                              fullWidth
                              variant="outlined"
                              size="small"
                              placeholder="Enter a number"
                              required={question.required}
                            />
                          )}

                          {question.type === "Date & Time" && (
                            <TextField
                              type="datetime-local"
                              fullWidth
                              variant="outlined"
                              size="small"
                              required={question.required}
                            />
                          )}
                          {question.type === "Time" && (
                            <TextField
                              type="time"
                              fullWidth
                              variant="outlined"
                              size="small"
                              required={question.required}
                            />
                          )}
                          {question.type === "Date" && (
                            <TextField
                              type="date"
                              fullWidth
                              variant="outlined"
                              size="small"
                              required={question.required}
                            />
                          )}
                          {question.type === "Location" && (
                            <Autocomplete
                              className="project-location-autocomplete"
                              freeSolo
                              onInputChange={(event, values) => {
                                handleAutocompleteChange(event, values);
                              }}
                              options={locationOption}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label=""
                                  id="outlined-basic"
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  name="location"
                                  placeholder="Please, Enter location  "
                                />
                              )}
                            />
                          )}

                          {question.type === "Media" && (
                            <FileUpload
                              name={question.id}
                              required={question.required}
                              onFilesChange={(files) => {}}
                            />
                          )}

                          {question.type === "Service Unit" && (
                            <Select
                              fullWidth
                              labelId=""
                              placeholder="Select Service Unit"
                              variant="outlined"
                              value={serviceUnit}
                              size="small"
                              label=""
                              onChange={(e) => setServiceUnit(e.target.value)}
                            >
                              <MenuItem value={"Ford Heavy Duty Truck"}>
                                Ford Heavy Duty Truck
                              </MenuItem>
                              <MenuItem value={"Sheet metal machinery"}>
                                Sheet metal machinery
                              </MenuItem>
                              <MenuItem value={"Titan Check Service"}>
                                Titan Check Service
                              </MenuItem>
                            </Select>
                          )}

                          {question.type === "Radio Buttons" && (
                            <FormControl
                              component="fieldset"
                              fullWidth
                              required={question.required}
                            >
                              <RadioGroup
                                name={`radio-group-${question.id}`} // Use question.id for unique naming
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  // justifyContent: "space-between",
                                }}
                              >
                                {question.options.map((option, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={option}
                                    control={<Radio />}
                                    label={option}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </form>
        </Box>
      )}
    </>
  );
}
