import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  IconButton,
  Tooltip,
  InputBase,
  Paper,
  Stack,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import DataGridTable from "../dataGrid";
import moment from "moment";
import DateRangePicker from "../dateRangePicker";
import FilterDrawer from "../../layout/filterDrawer";
import SearchIcon from "@mui/icons-material/Search";

import Pencil from "../../../assets/icons/pencil.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import DeleteIcon from "../../../assets/icons/deleteRed.svg";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import dayjs from "dayjs";
import {
  deleteFormInstanceById,
  fetchAllFormsInstances,
  getFormTypes,
} from "../../../redux/slices/forms.slice";

import CustomizedDialog from "../Dialog";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Selectt from "react-select";
import { PAGE_URL } from "../../../constants/pageUrl.constant";

const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    // padding: "14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const formTypeMapping = {
  employee_induction: "Employee Induction",
  work_permit: "Work Permit",
  inspection: "Inspection",
  custom: "Custom",
};
export default function FormLogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formHistoryData, setFormHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [dateRange_create, setDateRange_create] = useState(["", ""]);
  const [dateRange_update, setDateRange_update] = useState(["", ""]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [formTypeFilter, setFormTypeFilter] = useState(false);

  const [formTypes, setFormTypes] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [selectedFormId, setSelectedFormId] = useState();
  const [loading, setLoading] = useState(false);

  const getAllForms = async () => {
    setIsLoading(true);

    let custom_filter = {};

    if (dateRange_create?.length > 0) {
      custom_filter.created_at__gte =
        dateRange_create[0] == ""
          ? undefined
          : dayjs(dateRange_create[0]).format("YYYY-MM-DD");
      custom_filter.created_at__lte =
        dateRange_create[1] == ""
          ? undefined
          : dayjs(dateRange_create[1]).add(1, "day").format("YYYY-MM-DD");
    }
    if (dateRange_update?.length > 0) {
      custom_filter.updated_at__gte =
        dateRange_update[0] == ""
          ? undefined
          : dayjs(dateRange_update[0]).format("YYYY-MM-DD");
      custom_filter.updated_at__lte =
        dateRange_update[1] == ""
          ? undefined
          : dayjs(dateRange_update[1]).add(1, "day").format("YYYY-MM-DD");
    }
    if (formTypeFilter?.length > 0) {
      const selectedTypes = formTypeFilter?.map((item) => item.value);
      custom_filter.form__type__in = selectedTypes;
    }

    let response = await dispatch(
      fetchAllFormsInstances({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        sort: sortKey,
        // form__type__in: JSON.stringify(selectedTypes),
        custom_filter: JSON.stringify(custom_filter),
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setFormHistoryData(payload?.data);

      setTotalRow(payload?.total_object);
    }
    setIsLoading(false);
  };
  const deleteForm = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        deleteFormInstanceById({ formId: selectedFormId?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "form deleted succesfully",
          })
        );
        getAllForms();
        setOpenDeleteModal(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    getAllForms();
  }, [
    paginationModel,
    sortKey,
    formTypeFilter,
    dateRange_create,
    dateRange_update,
  ]);

  const fetchAllFormTypes = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormTypes());
      const { payload } = response;
      if (payload?.status) {
        setFormTypes(
          payload?.data?.map((item) => ({ label: item.name, value: item.type }))
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchAllFormTypes();
  }, []);
  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => navigate(`/forms/${params?.id}/view`)}
            >
              <img
                alt="View Details"
                src={EyeIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton
              sx={{ color: "#000000", padding: "0px" }}
              onClick={() => navigate(`/forms/${params?.id}/edit`)}
            >
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
                // onClick={() => {
                //   setDrawerState("edit");
                //   setIsOpenDrawer(true);
                //   setViewOpen(params.row);
                //   setSelectedRowData(params.row);
                // }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000", padding: "0" }}
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectedFormId(params.row);
              }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "formType",
      headerName: "Form Type",
      flex: 2,
      valueGetter: (params) => formTypeMapping[params.row.form?.type] || "-",

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "form__type")}
        >
          Form Type
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "form",
      headerName: "Form Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "form__title")}
        >
          Form Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: (params) => {
        return <div>{params.value.title}</div>;
      },
    },

    {
      field: "created_at",
      headerName: "Created At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 130, // Adjust minWidth as needed
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 140, // Adjust minWidth as needed
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
    },
  ];
  return (
    <>
      <div className="history">
        <Box
          className="content-header kb-list-header mb-4"
          sx={{ justifyContent: "flex-end !important" }}
        >
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => navigate(PAGE_URL.FORMS_TEMPLATES)}
            >
              Create Form
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
            >
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? formHistoryData.filter(
                    (item) =>
                      item.title
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                      item.form.title
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase())
                  )
                : formHistoryData
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>
      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box>
          <Typography variant="body1" component="label">
            Form Type
          </Typography>

          <Selectt
            styles={customStyles}
            isMulti
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={formTypes}
            value={formTypeFilter}
            onChange={(e) => {
              setFormTypeFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Report Type..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Created At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_create}
                onDateRangeChange={(e) => setDateRange_create(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Updated At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_update}
                onDateRangeChange={(e) => setDateRange_update(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
      </FilterDrawer>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedFormId?.title} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={deleteForm}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
}
