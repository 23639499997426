import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, Dialog, IconButton } from "@mui/material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    background: "#64bcd2",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    color: "#fff !important",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    justifyContent: "center",
  },
  "& .MuiDialog-paper": {
    width: "70%", // Base width as a percentage
    maxWidth: "1200px", // Set a maximum width

    [theme.breakpoints.down("sm")]: {
      width: "90%", // Adjust for small screens
    },
  },
}));

export default function CustomizedDialog({
  title,
  height,
  width,
  children,
  open,
  handleClose,
  className = "",
}) {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className={className}
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: width, // Set your custom width
            margin: "auto", // Center horizontally
          },
          "& .MuiDialogContent-root": {
            height: height,
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="text-white"
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.common?.white,
          }}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </BootstrapDialog>
    </React.Fragment>
  );
}
