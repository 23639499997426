import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  Paper,
} from "@mui/material";
import "../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Selectt from "react-select";
import CustomizedDialog from "../Dialog";
import {
  createServiceUnitTask,
  getAllServiceUnit,
  updateServiceUnitTask,
} from "../../../redux/slices/serviceUnit.slice";
import { getAllUsersThunk } from "../../../redux/slices/tenant.slice";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};
const severityOptions = [
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
];
const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    borderRadius: "4px",

    minHeight: "40px", // Mimicking MUI's small size
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 180,
    overflowY: "auto",
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? "rgba(25, 118, 210, 0.08)" // Highlight selected item with color
      : state.isFocused
      ? "#fafafa" // Hover effect
      : "white",
    color: "black",
    "&:active": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
};

const CreateTasks = (
  { isOpenDrawer, setIsOpenDrawer, drawerState, values, fetchAlltasks },
  props
) => {
  const [initialValues, setInitialValues] = useState({
    task_type: "inspection_task",
    severity: { value: "low", label: "Low" },
    due_date: "",
    service_unit: null,
    users: [],
    title: "",
    description: "",
  });
  const [users, setUsers] = useState([]);
  const [serviceUnitOptions, setServiceUnitOptions] = useState([]);
  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);

  const validationSchema = yup.object({
    // Title is required
    title: yup.string().required("Title is required"),

    // Due date is required
    due_date: yup
      .date()
      .required("Due Date is required")
      .min(new Date(), "Due Date cannot be in the past"),

    // Service Unit is required
    service_unit: yup
      .object()
      .shape({
        value: yup.string().required("Service Unit is required"),
        label: yup.string(),
      })
      .nullable()
      .required("Service Unit is required"),

    // At least one user must be selected (Multi-Select validation)
    users: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .min(1, "At least one user must be selected")
      .required("Assigned To is required"),

    // Description is optional
    description: yup.string(),
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (valuess) => {
      setIsLoading(true);

      try {
        let response = await dispatch(
          drawerState === "edit"
            ? updateServiceUnitTask({
                data: {
                  ...valuess,
                  service_unit: valuess.service_unit.value,
                  severity: valuess.severity.value,
                  users: valuess.users?.map((item) => item.value),
                },
                id: values?.id,
              })
            : createServiceUnitTask({
                data: {
                  ...valuess,
                  service_unit: valuess.service_unit.value,
                  severity: valuess.severity.value,
                  users: valuess.users?.map((item) => item.value),
                },
              })
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message:
                drawerState === "edit"
                  ? "Task Updated Succesfully."
                  : "Task Created Succesfully.",
            })
          );
          setIsOpenDrawer(false);
          fetchAlltasks();
          formik.resetForm();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });

  const handleCloseDrawer = () => {
    formik.resetForm();
    setIsOpenDrawer(false);
  };

  const fetchAllUsers = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 100000000,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = payload?.data.map((item) => ({
        label: item.first_name + " " + item.last_name,
        value: item.id,
      }));

      setUsers(temp);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  useEffect(
    () => {
      fetchAllUsers();
    }, // eslint-disable-next-line
    []
  );

  const fetchAllserviceUnit = async () => {
    setIsLoading(true);

    try {
      let response = await dispatch(
        getAllServiceUnit({
          page_number: 1,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setServiceUnitOptions(
          payload?.data?.map((item, idx) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchAllserviceUnit();
  }, []);

  const setTaskValues = () => {
    let data = {
      title: values?.title,
      description: values?.description,
      service_unit: serviceUnitOptions?.find(
        (item, value) => item.value === +values?.service_unit?.id
      ),

      users: values?.users?.map((user) => ({
        value: user.id,
        label: user.first_name + " " + user.last_name,
      })),
      due_date: values.due_date
        ? new Date(values.due_date).toISOString().split("T")[0]
        : "",
      severity: severityOptions?.find(
        (item) => item.value === values?.severity
      ),
    };
    setInitialValues(data);
    formik.setValues(data);
  };
  useEffect(() => {
    if (drawerState !== "create") setTaskValues();
  }, [values, serviceUnitOptions]);
  console.log(formik.values.service_unit, drawerState);

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={
          drawerState === "view"
            ? "Task Detail"
            : drawerState === "create"
            ? "Create Task"
            : "Edit Task"
        }
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent>
          <form className={drawerState === "view" && "form-disabled"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Title
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Title"
                    size="small"
                    name="title"
                    {...formik.getFieldProps("title")}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="form-error-message">
                      {formik.errors.title}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    multiline
                    rows="3"
                    variant="outlined"
                    placeholder="Enter Description"
                    size="small"
                    name="description"
                    {...formik.getFieldProps("description")}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Due Date
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    type="date"
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Select Due Date"
                    size="small"
                    name="Due Date"
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      }, // Restrict past dates
                    }}
                    {...formik.getFieldProps("due_date")}
                  />
                  {formik.touched.due_date && formik.errors.due_date && (
                    <div className="form-error-message">
                      {formik.errors.due_date}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Severity
                  </Typography>
                  <Selectt
                    options={severityOptions}
                    value={formik?.values?.severity}
                    onChange={(e) => {
                      formik.setFieldValue("severity", e);
                    }}
                    styles={customStyles}
                    placeholder="Select Severity"
                    menuPosition="fixed"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    classNamePrefix="select"
                  />
                  {formik.touched.severity && formik.errors.severity && (
                    <div className="form-error-message">
                      {formik.errors.severity}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Select Service Unit
                  </Typography>
                  <Selectt
                    options={serviceUnitOptions}
                    value={formik?.values?.service_unit}
                    onChange={(e) => {
                      formik.setFieldValue("service_unit", e);
                    }}
                    styles={customStyles}
                    placeholder="Select Service Unit"
                    menuPosition="fixed"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    classNamePrefix="select"
                  />
                  {formik.touched.service_unit &&
                    formik.errors.service_unit && (
                      <div className="form-error-message">
                        {formik.errors.service_unit}
                      </div>
                    )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Assigned To
                  </Typography>
                  <Selectt
                    styles={customStyles}
                    isMulti
                    size="small"
                    fullWidth
                    options={users}
                    value={formik?.values?.users}
                    onChange={(e) => {
                      formik.setFieldValue("users", e);
                    }}
                    classNamePrefix="select"
                    placeholder={"Select Users..."}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPosition="fixed"
                  ></Selectt>
                  {formik.touched.users && formik.errors.users && (
                    <div className="form-error-message">
                      {formik.errors.users}
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {drawerState !== "view" && (
          <DialogActions>
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Save
                </Button>

                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </DialogActions>
        )}
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status ( IN REVIEW => REVIEW COMPLETED )"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the <b>{formik?.values?.title}</b>{" "}
              as reviewed and completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={() => {
                formik.setFieldValue("clickedButton", "ReviewComplete");
                formik.handleSubmit();
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default CreateTasks;
