import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Box,
  IconButton,
  Tooltip,
  Select,
  InputBase,
  Paper,
  Stack,
  Chip,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import { IoDocumentText } from "react-icons/io5";
import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { getAllForm300 } from "../../../redux/slices/form300.slice";
import DataGridTable from "../dataGrid";
import moment from "moment";
import DateRangePicker from "../dateRangePicker";
import FilterDrawer from "../../layout/filterDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { MdOutlineWatchLater, MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import DeleteIcon from "../../../assets/icons/deleteRed.svg";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import dayjs from "dayjs";
import {
  deleteFormInstanceById,
  fetchAllFormsInstances,
  getAllForm,
  getFormSubTypesByid,
  getFormTypes,
} from "../../../redux/slices/forms.slice";
import { Status } from "../../../utils/icon/CommonFunctions";
import CustomizedDialog from "../Dialog";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Selectt from "react-select";
import { PAGE_URL } from "../../../constants/pageUrl.constant";
import CreateTasks from "./createTask";
import {
  deleteServiceUnitTask,
  getAllServiceUnitTask,
  updateServiceUnitTask,
} from "../../../redux/slices/serviceUnit.slice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/AccessTime";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
const formTypeMapping = {
  employee_induction: "Employee Induction",
  work_permit: "Work Permit",
  inspection: "Inspection",
};

const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    // padding: "14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const severityOptions = [
  { value: "All", label: "All" },
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
];
const statusOptions = [
  { value: "All", label: "All" },
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
  { value: "up_coming", label: "Upcoming" },
];
export default function TaskList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [taskData, setTaskData] = useState([]);

  const [totalRow, setTotalRow] = useState(0);
  const { serviceUnitId } = useParams();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [dateRange_create, setDateRange_create] = useState(["", ""]);
  const [dateRange_update, setDateRange_update] = useState(["", ""]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const [formTypes, setFormTypes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [forms, setForms] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [selectedFormId, setSelectedFormId] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");
  const [reviewCompleteOpen, setReviewCompleteOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState({
    value: "All",
    label: "All",
  });
  const [severityFilter, setSeverityFilter] = useState({
    value: "All",
    label: "All",
  });
  const getAllTasks = async () => {
    setLoading(true);
    let custom_filter = {};

    if (severityFilter.length > 0) {
      custom_filter.severity__in = severityFilter.map((item) => item.value);
    }

    let response = await dispatch(
      getAllServiceUnitTask({
        page_number: 1,

        sort: sortKey,
        service_unit: serviceUnitId,
        status:
          statusFilter?.value === "All"
            ? undefined
            : statusFilter?.value === "completed"
            ? statusFilter?.value
            : "up_coming",
        severity:
          severityFilter?.value === "All" ? undefined : severityFilter?.value,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let tempData = [];
      if (statusFilter?.value === "pending") {
        tempData = payload?.data?.filter((item) =>
          isDueDatePast(item.due_date)
        );
      } else if (statusFilter?.value === "up_coming") {
        tempData = payload?.data?.filter(
          (item) => !isDueDatePast(item.due_date)
        );
      } else {
        tempData = payload?.data;
      }
      setTaskData(tempData);

      setTotalRow(tempData?.length);
    }
    setLoading(false);
  };

  const deleteForm = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        deleteServiceUnitTask({ id: selectedFormId?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "form deleted succesfully",
          })
        );
        getAllTasks();
        setOpenDeleteModal(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  const fetchAllFormTypes = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormTypes());
      const { payload } = response;
      if (payload?.status) {
        setFormTypes(
          payload?.data?.map((item) => ({ label: item.name, value: item.type }))
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    getAllTasks();
  }, [paginationModel, sortKey, severityFilter, statusFilter]);

  const fetchInspectionForms = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        getAllForm({
          custom_filter: JSON.stringify({ type__in: ["inspection"] }),

          list: "all",
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setForms(
          payload?.data?.map((item) => ({ label: item.title, value: item.id }))
        );
        if (payload?.data?.length > 0) {
          setSelectedForm(
            payload?.data?.map((item) => ({
              label: item.title,
              value: item.id,
            }))[0]
          );
        }
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    fetchInspectionForms();
  }, []);

  useEffect(() => {
    fetchAllFormTypes();
  }, []);

  const handleUpdateStatus = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        updateServiceUnitTask({
          data: { status: "completed" },
          id: selectedFormId?.id,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Task Status Updated Successfully.",
          })
        );
        setReviewCompleteOpen(false);
        getAllTasks();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setDrawerState("view");
                setIsOpenDrawer(true);
                setSelectedFormId(params?.row);
              }}
            >
              <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton
              sx={{ color: "#000000", padding: "0px" }}
              onClick={() => {
                setDrawerState("edit");
                setIsOpenDrawer(true);
                setSelectedFormId(params?.row);
              }}
            >
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>

          {params.row.status === "completed" ? (
            <Tooltip title="Completed">
              <IconButton sx={{ color: "#000000" }} disabled>
                <IoIosCheckmarkCircle size={24} color="green" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Mark as Complete">
              <IconButton
                sx={{ color: "#000000" }}
                onClick={() => {
                  setReviewCompleteOpen(true);
                  setSelectedFormId(params?.row);
                }}
              >
                <MdOutlineWatchLater
                  size={24}
                  cursor={"pointer"}
                  color="#036185"
                />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000", padding: "0" }}
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectedFormId(params?.row);
              }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }
  const isDueDatePast = (dueDate) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    const due = new Date(dueDate).toISOString().split("T")[0]; // Get due date in YYYY-MM-DD format

    return due < today; // Compare date strings
  };

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "title")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "form__type")}
        >
          Severity
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 1, // Flex value of 2 (20% of the space if the total flex is 10)
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "form__title")}
        >
          Due Date
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      // Flex value of 1 (10% of the space if the total flex is 10)

      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Status
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: ({ row }) => {
        if (row.status !== "completed" && isDueDatePast(row.due_date)) {
          return (
            <Chip
              label={Status["PENDING"]?.label}
              style={{
                backgroundColor: Status["PENDING"]?.color,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            />
          );
        } else if (row.status) {
          return (
            <Chip
              label={Status[row?.status]?.label}
              style={{
                backgroundColor: Status[row?.status]?.color,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      // Flex value of 1 (10% of the space if the total flex is 10)

      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
    },
  ];
  return (
    <>
      <div className="history">
        <Box
          className="content-header kb-list-header mb-4"
          sx={{ justifyContent: "flex-end !important" }}
        >
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => {
                setDrawerState("create");
                setIsOpenDrawer(true);
              }}
            >
              Create Task
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
              style={{ height: "44px" }}
            >
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? taskData.filter((item) =>
                    item.title
                      ?.toLowerCase()
                      .includes(searchTerm?.toLowerCase())
                  )
                : taskData.slice(
                    paginationModel.page * paginationModel.pageSize,
                    (paginationModel.page + 1) * paginationModel.pageSize
                  )
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={loading}
            paginationModel={paginationModel}
            totalRow={totalRow}
            height="calc(100vh - 345px)"
          />
        </Box>
      </div>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedFormId?.title} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={deleteForm}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Update Status"}
        open={reviewCompleteOpen}
        handleClose={() => {
          setReviewCompleteOpen(false);
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              Are You Sure You want to mark the <b>{selectedFormId?.title}</b>{" "}
              as completed?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={handleUpdateStatus}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setReviewCompleteOpen(false)}
            >
              {loading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      {isOpenDrawer && (
        <CreateTasks
          drawerState={drawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          values={selectedFormId}
          fetchAlltasks={getAllTasks}
        />
      )}

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box>
          <Typography variant="body1" component="label">
            Severity
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={severityOptions}
            value={severityFilter}
            onChange={(e) => {
              setSeverityFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Severity..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Task Status
          </Typography>

          <Selectt
            styles={customStyles}
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={statusOptions}
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Status..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
      </FilterDrawer>
    </>
  );
}
