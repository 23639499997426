import {
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Box,
  Tooltip,
  IconButton,
  Divider,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InsightsIcon from "@mui/icons-material/Insights";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/slices/common.slice";
import { getTODOList, updateTodo } from "../../redux/slices/tenant.slice";

import PendingIcon from "@mui/icons-material/AccessTime";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import Insights from "./insights";
const ReportDashboard = () => {
  const [todos, setTodos] = useState([]);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [mainTab, setMainTab] = useState(0); // New state for main tabs
  const dispatch = useDispatch();

  // Fetch TODO list based on active tab (Inspection or Incident tasks)
  const fetchTODOList = async () => {
    setLoading(true);
    let response = await dispatch(
      getTODOList({
        task_type: activeTab === 0 ? "inspection_task" : "incident_task",
      })
    );
    const { payload } = response;

    if (payload?.status) {
      setTodos(payload?.data);
      setToken(payload?.token);
    } else {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTODOList();
  }, [activeTab]);

  const handleUpdateIsReviewed = async (event, id) => {
    event.stopPropagation();

    try {
      let response = await dispatch(
        updateTodo({
          data: { is_reviewed: true },
          todoId: id,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        await fetchTODOList();
        dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "TODO is Marked as Reviewed!",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
  };

  const TabPanel = ({ children, value, index, ...other }) => (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );

  // Main Tab Change Handler
  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue);
  };

  // Sub Tab Change Handler
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // TodoList Component
  const TodoList = ({ todos, loading }) => (
    <Box sx={{ height: "350px", overflowY: "auto" }} p={1}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : todos?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="subtitle1">
            No Tasks Currently Assigned
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {todos.map((todo) => (
            <Grid item xs={12} sm={6} md={6} key={todo.id}>
              <Card
                sx={{
                  cursor: "pointer",
                  height: "160px",
                  "&:hover": { boxShadow: 3 },
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    {activeTab === 1 && (
                      <Grid item>
                        <Avatar
                          src={
                            todo?.report?.image &&
                            `${todo?.report?.image[0]}?${token}`
                          }
                          alt={todo.title}
                          sx={{ width: 72, height: 72, borderRadius: "10px" }}
                        />
                      </Grid>
                    )}
                    <Grid item xs>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {todo.title || "No Title Available"}
                      </Typography>
                      <Typography variant="label" color="textSecondary">
                        {todo.description?.length > 130
                          ? `${todo.description.slice(0, 130)}...`
                          : todo.description || "No description available"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      mt: "auto",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        {moment(todo.created_at, "HH:mm").format("hh:mm A")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "&:hover": {
                            backgroundColor: "#fff4e6",
                            boxShadow: 1,
                            transform: "translateY(-1px)",
                          },
                        }}
                        onClick={(e) => handleUpdateIsReviewed(e, todo.id)}
                      >
                        <Tooltip title="Mark as Reviewed" arrow>
                          <IconButton
                            sx={{ color: "warning.main", marginRight: "4px" }}
                          >
                            <PendingIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Typography
                          variant="label"
                          sx={{ color: "text.primary", fontWeight: 500 }}
                        >
                          Mark As Reviewed
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );

  return (
    <div>
      {/* Main Tabs for My Dashboard and Insights */}
      <Tabs
        value={mainTab}
        onChange={handleMainTabChange}
        aria-label="Main Dashboard Tabs"
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
        className="configure-action-tab"
        sx={{ mb: 2 }}
      >
        <Tab
          icon={<DashboardIcon />}
          label="My Dashboard"
          iconPosition="start"
          sx={{ minHeight: "auto" }}
        />
        <Tab
          icon={<LeaderboardIcon />}
          label="Insights"
          iconPosition="start"
          sx={{ minHeight: "auto" }}
        />
      </Tabs>

      {/* Content for Main Tabs */}
      <TabPanel value={mainTab} index={0}>
        {/* My Dashboard Content */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">TODO List</Typography>

                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="TODO tabs"
                    className="configure-action-tab"
                  >
                    <Tab label="Inspection Tasks" />
                    <Tab label="Incident Tasks" />
                  </Tabs>
                </Box>

                <TabPanel value={activeTab} index={0}>
                  <TodoList todos={todos} loading={loading} />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <TodoList todos={todos} loading={loading} />
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={mainTab} index={1}>
        <Insights />
      </TabPanel>
    </div>
  );
};

export default ReportDashboard;
