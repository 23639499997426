import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Tooltip,
  Paper,
  InputBase,
  Stack,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";

import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../../assets/icons/pencil.svg";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DeleteIcon from "../../../../assets/icons/deleteRed.svg";
import axios from "axios";
import dayjs from "dayjs";
import { getAllForm301 } from "../../../../redux/slices/form300.slice";
import DataGridTable from "../../dataGrid";
import { PAGE_URL } from "../../../../constants/pageUrl.constant";
import {
  deleteServiceUnitType,
  getAllServiceUnitType,
} from "../../../../redux/slices/serviceUnit.slice";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import CustomizedDialog from "../../Dialog";
import FormRenderer from "./formRenderer";
export default function ServiceTypeList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const [dateRange_create, setDateRange_create] = useState(["", ""]);
  const [dateRange_update, setDateRange_update] = useState(["", ""]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [sortKey, setSortKey] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedServiceUnitType, setSelectedServiceUnitType] = useState();

  const fetchAllServiceType = async () => {
    setIsLoading(true);

    try {
      let response = await dispatch(
        getAllServiceUnitType({
          page_number: paginationModel.page + 1,
          page_size: paginationModel.pageSize,
          //   custom_filter: JSON.stringify(custom_filter),
          sort: sortKey,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setServiceTypes(payload?.data);

        setTotalRow(payload?.total_object);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchAllServiceType();
  }, [paginationModel, dateRange_update, dateRange_create, sortKey]);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        deleteServiceUnitType({ id: selectedServiceUnitType?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        setOpenDeleteModal(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Service Type Deleted succesfully",
          })
        );
        fetchAllServiceType();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }
  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "title",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "name")}
        >
          Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 100,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "description")}
        >
          Description
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Stack direction="row" spacing={0} alignItems="center">
            <Tooltip title="View">
              <IconButton
                onClick={() => {
                  setSelectedServiceUnitType(params.row);
                  setOpenPreview(true);
                }}
              >
                <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                sx={{ color: "#000000", padding: "0px" }}
                onClick={() => navigate(`/settings/service-type/${id}/edit`)}
              >
                <img
                  alt="Edit Details"
                  src={Pencil}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                sx={{ color: "#000000", padding: "0" }}
                onClick={() => {
                  setOpenDeleteModal(true);
                  setSelectedServiceUnitType(params.row);
                }}
              >
                <img
                  alt="Delete Details"
                  src={DeleteIcon}
                  width={24}
                  height={24}
                  className="actionIcon"
                  cursor={"pointer"}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <div className="history">
        <Box className="content-header kb-list-header mb-4">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/settings");
            }}
            startIcon={<ArrowBackIcon />}
            className="primary-button back-button"
          >
            Back
          </Button>
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => navigate(PAGE_URL.SERVICE_TYPE_CREATE)}
            >
              Service Unit Type
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? serviceTypes.filter(
                    (item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      item.description
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                : serviceTypes
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Preview Form"}
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
      >
        <DialogContent>
          <FormRenderer id={selectedServiceUnitType?.id} />
        </DialogContent>
      </CustomizedDialog>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedServiceUnitType?.name} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
}
