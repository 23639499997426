import React from "react";
import { Box, Button, Typography, Paper, Avatar } from "@mui/material";

const FileUpload = ({
  idd,
  label,
  required,
  setErrors,
  selectedFiles,
  setSelectedFiles,
}) => {
  // const [temp, setTemp] = useState();
  function removeUntilFirstComma(str) {
    const commaIndex = str.indexOf(",");
    if (commaIndex !== -1) {
      return str.substring(commaIndex + 1); // +1 to skip the comma itself
    }
    return str; // return original string if no comma is found
  }

  const handleFileChange = async (event, currentLabel) => {
    const files = Array.from(event.target.files);
    const newFilesData = [];

    // Convert each file to base64 and save its name
    for (const file of files) {
      const base64 = await toBase64(file);
      newFilesData.push({
        base64Image: removeUntilFirstComma(base64),
        extension: file.type.split("/")[1], // Get the file extension from type
        name: file.name, // Store the file name
        id: idd,
      });
    }

    const newFiles = [...selectedFiles, ...newFilesData];
    if (newFiles.length > 0) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[idd];
        return newErrors;
      });
    }
    setSelectedFiles(newFiles);
    // setTemp(newFiles);
  };

  // Helper function to convert file to base64
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const removeFile = (fileToRemove) => {
    const newFiles = selectedFiles.filter(
      (file) => file.base64Image !== fileToRemove.base64Image
    );
    setSelectedFiles(newFiles);
  };

  return (
    <Box>
      <Paper
        sx={{
          padding: "20px",
          border: "2px dashed #ddd",
          textAlign: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <input
          type="file"
          name={idd}
          multiple
          accept="image/*"
          onChange={(event) => handleFileChange(event, label)} // Pass the label dynamically
          style={{ display: "none" }}
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <Typography variant="label" sx={{ margin: "auto" }}>
            Click to upload
          </Typography>
        </label>
      </Paper>

      <Box sx={{ mt: 2, display: "flex", gap: "20px" }}>
        {selectedFiles?.map((file, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Avatar
              src={`data:image/${file.extension};base64,${file.base64Image}`}
              alt={file.name}
              sx={{ width: 100, height: 100, mb: 2 }}
            />
            <Button
              variant="outlined"
              color="error"
              onClick={() => removeFile(file)}
            >
              Remove Image
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FileUpload;
