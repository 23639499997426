import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  Box,
  Collapse,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  DialogContent,
  ButtonGroup,
  Typography,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RiAddCircleLine, RiDeleteBinLine } from "react-icons/ri";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import TextFieldIcon from "@mui/icons-material/TextFields";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import FormRenderer from "./formRenderer";
import DateRangeIcon from "@mui/icons-material/DateRange";

import { useDispatch } from "react-redux";

import CustomizedDialog from "../../Dialog";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import {
  createServiceUnitType,
  getAllServiceUnitType,
  getServiceUnitTypeById,
  updateServiceUnitType,
} from "../../../../redux/slices/serviceUnit.slice";
import Loader from "../../Loader";

//const initialData = [];
export default function DynamicFormBuilder({ mode }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [questions, setQuestions] = useState([
    {
      id: `q-1`,
      type: "Text Field",
      label: "",

      required: false,
      width: 12,
    },
  ]);
  const [expandedQuestionId, setExpandedQuestionId] = useState(["q-1"]);

  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const navigate = useNavigate();

  const questionTypes = ["Text Field", "Date"];
  const questionTypeIcons = {
    "Text Field": <TextFieldIcon />,

    Date: <DateRangeIcon />,
    // "Service Unit": <PrecisionManufacturingIcon />,
  };
  console.log(questions);
  const addQuestion = () => {
    const newQuestion = {
      id: `q-${questions.length + 1}`,
      type: "Text Field",
      label: "",

      required: false,
      width: 12,
    };

    setQuestions([...questions, newQuestion]);

    // Expand the newly added question
    setExpandedQuestionId((prevState) => [...prevState, newQuestion.id]);
  };

  // Remove a specific question
  const removeQuestion = (questionId) => {
    const updatedQuestions = questions.filter((q) => q.id !== questionId);
    setQuestions(updatedQuestions);
  };

  // Toggle question dropdown (expand/collapse)
  const toggleQuestionDropdown = (questionId) => {
    setExpandedQuestionId(
      (prevState) =>
        prevState.includes(questionId)
          ? prevState.filter((id) => id !== questionId) // Collapse
          : [...prevState, questionId] // Expand
    );
  };

  // Handle drag and drop for reordering questions
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const reorderedQuestions = Array.from(questions);
    const [removed] = reorderedQuestions.splice(source.index, 1);
    reorderedQuestions.splice(destination.index, 0, removed);

    setQuestions(reorderedQuestions);
  };

  const handleRequiredChange = (questionId) => {
    const updatedQuestions = questions.map((q) =>
      q.id === questionId ? { ...q, required: !q.required } : q
    );
    setQuestions(updatedQuestions);
  };

  const handleWidthChange = (questionId) => {
    const updatedQuestions = questions.map((q) =>
      q.id === questionId ? { ...q, width: q.width === 12 ? 6 : 12 } : q
    );
    setQuestions(updatedQuestions);
  };

  const fetchServiceUnitTypeById = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getServiceUnitTypeById({ id }));
      const { payload } = response;
      if (payload?.status) {
        setExpandedQuestionId(
          payload?.data?.service_unit_json?.map((item) => item.id)
        );
        setQuestions(payload?.data?.service_unit_json);
        setFormTitle(payload?.data?.name);
        setFormDescription(payload?.data?.description);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchServiceUnitTypeById();
    }
  }, [id]);

  const handleSubmit = async () => {
    if (!formTitle) {
      setTitleError("Title is Required");
      return;
    }
    if (!formDescription) {
      setDescriptionError("Description is Required");
      return;
    }

    const data = {
      name: formTitle,
      description: formDescription,
      service_unit_json: questions,
    };

    setLoading(true);
    try {
      let response;
      if (mode === "create") {
        response = await dispatch(
          createServiceUnitType({
            data,
          })
        );
      } else {
        response = await dispatch(
          updateServiceUnitType({
            data,
            id,
          })
        );
      }

      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message:
              mode === "create"
                ? "Service Unit Type created Succesfully."
                : "Service Unit Type updated Succesfully.",
          })
        );
        navigate("/settings/service-type");
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      console.log(error);
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }
    setLoading(false);
  };
  return (
    <>
      {loading && <Loader />}
      <Box sx={{ padding: "0 12px" }}>
        <Box
          className="content-header kb-list-header mb-4"
          sx={{ alignItems: "flex-end !important" }}
        >
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/settings/service-type");
            }}
            startIcon={<ArrowBackIcon />}
            className="primary-button back-button"
          >
            Back
          </Button>
          <Box
            variant="div"
            component="div"
            className="content-header-right"
            sx={{ alignItems: "flex-end !important" }}
          >
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<VisibilityIcon />}
              onClick={() => setOpenPreview(true)}
            >
              Preview Form
            </Button>
          </Box>
        </Box>
        <Box
          className="table-container"
          sx={{
            height: "calc(100vh - 170px)",
            overflowY: "auto",
            backgroundColor: "white",
            padding: "12px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" component="label">
                Service Type Title
              </Typography>
              <TextField
                value={formTitle}
                onChange={(e) => setFormTitle(e.target.value)}
                sx={{ backgroundColor: "white" }}
                required
                size="small"
              />
              {titleError && (
                <div className="form-error-message">{titleError}</div>
              )}
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" component="label">
                Service Type Description
              </Typography>
              <TextField
                value={formDescription}
                onChange={(e) => setFormDescription(e.target.value)}
                sx={{ backgroundColor: "white" }}
                required
                size="small"
              />
              {descriptionError && (
                <div className="form-error-message">{descriptionError}</div>
              )}
            </Grid>

            {!loading && (
              <Grid item xs={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="questions"
                    direction="vertical"
                    type="question"
                  >
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {questions.map((question, index) => (
                          <Draggable
                            key={question.id}
                            draggableId={question.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <Grid
                                container
                                alignItems="center"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginBottom: "3px",
                                  padding: "2px",
                                }}
                              >
                                <Grid
                                  sx={{
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#fff",
                                  }}
                                  item
                                  xs={11}
                                >
                                  <Grid container alignItems="center">
                                    <Grid item xs={1}>
                                      <Box sx={{ margin: "0 5px" }}>
                                        <DragIndicatorIcon />
                                        <IconButton
                                          onClick={() =>
                                            toggleQuestionDropdown(question.id)
                                          }
                                        >
                                          {expandedQuestionId.includes(
                                            question.id
                                          ) ? (
                                            <ArrowDropUpIcon />
                                          ) : (
                                            <ArrowDropDownIcon />
                                          )}
                                        </IconButton>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <TextField
                                        placeholder="Question Title"
                                        value={question.label}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => {
                                          const updatedQuestions =
                                            questions.map((q) =>
                                              q.id === question.id
                                                ? {
                                                    ...q,
                                                    label: e.target.value,
                                                  }
                                                : q
                                            );
                                          setQuestions(updatedQuestions);
                                        }}
                                        InputProps={{
                                          disableUnderline: true,
                                          sx: {
                                            "& .MuiOutlinedInput-notchedOutline":
                                              {
                                                border: "none",
                                              },
                                            borderRight: "1px solid #ccc",
                                            borderRadius: "0px",
                                          },
                                        }}
                                        fullWidth
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <Select
                                        value={question.type}
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: "none",
                                            },
                                          "& .MuiSelect-select": {
                                            display: "flex",
                                            alignItems: "center",
                                          },
                                          "&:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "#888",
                                            },
                                          "& .MuiInputBase-input": {
                                            height: "20px !important",
                                            minHeight: "0",
                                          },
                                        }}
                                        onChange={(e) => {
                                          const newType = e.target.value;
                                          const updatedQuestions =
                                            questions.map((q) =>
                                              q.id === question.id
                                                ? {
                                                    ...q,
                                                    type: newType,
                                                    ...(newType ===
                                                      "Select" && {
                                                      options: [],
                                                    }),
                                                  }
                                                : q
                                            );
                                          setQuestions(updatedQuestions);
                                        }}
                                        fullWidth
                                      >
                                        {questionTypes.map((type) => (
                                          <MenuItem key={type} value={type}>
                                            <ListItemIcon>
                                              {questionTypeIcons[type]}
                                            </ListItemIcon>
                                            <ListItemText primary={type} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Grid>

                                    <Collapse
                                      in={expandedQuestionId.includes(
                                        question.id
                                      )}
                                      timeout="auto"
                                      unmountOnExit
                                      sx={{
                                        backgroundColor: "#f0f8fb",
                                        width: "100%",
                                      }}
                                    >
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        p="4px"
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={question.required}
                                              onChange={() =>
                                                handleRequiredChange(
                                                  question.id
                                                )
                                              }
                                            />
                                          }
                                          label="Required"
                                          sx={{
                                            ml: 2,
                                            borderRight: "1px solid #ccc",
                                            paddingRight: 2,
                                          }}
                                        />
                                        <ButtonGroup variant="outlined">
                                          <Button
                                            onClick={() =>
                                              handleWidthChange(question.id)
                                            }
                                            variant={
                                              question.width === 12
                                                ? "contained"
                                                : "outlined"
                                            }
                                          >
                                            Full Width
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              handleWidthChange(question.id)
                                            }
                                            variant={
                                              question.width === 6
                                                ? "contained"
                                                : "outlined"
                                            }
                                          >
                                            Half Width
                                          </Button>
                                        </ButtonGroup>
                                      </Box>
                                    </Collapse>
                                  </Grid>
                                </Grid>
                                {questions.length > 1 && (
                                  <Grid xs={1} item>
                                    <IconButton
                                      onClick={() =>
                                        removeQuestion(question.id)
                                      }
                                      sx={{
                                        backgroundColor: "white",
                                        color: "#036185",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        border: "1px solid #036185",
                                        marginLeft: "20px",
                                        width: "46px !important",
                                        height: "46px !important",
                                      }}
                                    >
                                      <RiDeleteBinLine />
                                    </IconButton>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, width: "fit-content" }}
                    onClick={addQuestion}
                    className="primary-button"
                    endIcon={<RiAddCircleLine />}
                  >
                    Add Question
                  </Button>
                </DragDropContext>
              </Grid>
            )}
          </Grid>
        </Box>
        <CustomizedDialog
          className="modal-scroll"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          title={"Preview Form"}
          open={openPreview}
          handleClose={() => setOpenPreview(false)}
        >
          <DialogContent>
            <FormRenderer questions={questions} />
          </DialogContent>
        </CustomizedDialog>
      </Box>
    </>
  );
}
