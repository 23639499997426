import { Box, Card, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BsPersonWorkspace } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi2";
import { HiMiniUsers } from "react-icons/hi2";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { GrSettingsOption } from "react-icons/gr";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import "../../../styles/settings.scss";
import { PAGE_URL } from "../../../constants/pageUrl.constant";
import { RiAccountCircleLine } from "react-icons/ri";
import ExternalAssitant from "../../../utils/icon/ExternalAssitant";
import { FaWpforms } from "react-icons/fa";
import { SiGoogleforms } from "react-icons/si";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

const Settings = () => {
  const Navigate = useNavigate();
  return (
    <Box
      sx={{ maxHeight: "calc(100vh - 80px)", overflowY: "auto" }}
      className="table-container"
    >
      <Grid
        container
        spacing={2}
        // paddingBottom={"10px"}
        justifyContent={"left"}
        // height={"100%"}
      >
        {/* Workspace option  */}
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Card
            className="settingCard"
            size="small"
            onClick={() => Navigate(PAGE_URL.WORKSPACE)}
          >
            <div className="text-center" style={{ padding: 15 }}>
              <BsPersonWorkspace fontSize={64} color="#036185" />
              <h5 className="mt-4 fw-bold" style={{ color: "#036185" }}>
                Workspace
              </h5>
            </div>
          </Card>
        </Grid>

        {/* User option  */}
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Card
            className="settingCard"
            onClick={() => Navigate(PAGE_URL.USERS)}
          >
            <div className="text-center" style={{ padding: 15 }}>
              <HiMiniUsers fontSize={64} color="#036185" />
              <h5 className="mt-4 fw-bold" style={{ color: "#036185" }}>
                Users
              </h5>
            </div>
          </Card>
        </Grid>

        {/* Project option  */}
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Card
            className="settingCard"
            onClick={() => Navigate(PAGE_URL.EXTERNAL_ASSISTANT)}
          >
            <div className="text-center" style={{ padding: 15 }}>
              <RiAccountCircleLine fontSize={64} color="#036185" />
              <h5 className="mt-4 fw-bold" style={{ color: "#036185" }}>
                Assistants
              </h5>
            </div>
          </Card>
        </Grid>

        {/* Configure action option  */}
        {/* <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Card
            className="settingCard"
            onClick={() => Navigate(PAGE_URL.CONFIGURE_ACTIONS)}
          >
            <div className="text-center" style={{ padding: 15 }}>
              <GrSettingsOption fontSize={64} color="#036185" />
              <h5 className="mt-4 fw-bold" style={{ color: "#036185" }}>
                Configure Actions
              </h5>
            </div>
          </Card>
        </Grid> */}

        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Card
            className="settingCard"
            onClick={() => Navigate(PAGE_URL.CONFIGURE_CHAT)}
          >
            <div className="text-center" style={{ padding: 15 }}>
              <ExternalAssitant fontSize={102} color="#036185" />
              <h5 className="mt-4 fw-bold" style={{ color: "#036185" }}>
                External Asisstant
              </h5>
            </div>
          </Card>
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Card
            className="settingCard"
            onClick={() => Navigate(PAGE_URL.INTEGRATION)}
          >
            <div className="text-center" style={{ padding: 15 }}>
              <MdOutlineIntegrationInstructions fontSize={64} color="#036185" />
              <h5 className="mt-4 fw-bold" style={{ color: "#036185" }}>
                Integration
              </h5>
            </div>
          </Card>
        </Grid>

        {/* <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Card
            className="settingCard"
            onClick={() => Navigate(PAGE_URL.BILLINGS_AND_PLANS)}
          >
            <div className="text-center" style={{ padding: 15 }}>
              <FaMoneyBillTrendUp fontSize={64} color="#036185" />
              <h5 className="mt-4 fw-bold" style={{ color: "#036185" }}>
                Bilings And Plans
              </h5>
            </div>
          </Card>
        </Grid> */}
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Card
            className="settingCard"
            onClick={() => Navigate(PAGE_URL.FORMBUILDER)}
          >
            <div className="text-center" style={{ padding: 15 }}>
              <SiGoogleforms fontSize={64} color="#036185" />
              <h5 className="mt-4 fw-bold" style={{ color: "#036185" }}>
                Templates
              </h5>
            </div>
          </Card>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Card
            className="settingCard"
            onClick={() => Navigate(PAGE_URL.SERVICE_TYPE)}
          >
            <div className="text-center" style={{ padding: 15 }}>
              <PrecisionManufacturingIcon
                sx={{ fontSize: 64, color: "#036185" }}
              />
              <h5 className="mt-4 fw-bold" style={{ color: "#036185" }}>
                Service Unit Type
              </h5>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
