import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Box,
  IconButton,
  Tooltip,
  Select,
  InputBase,
  Paper,
  Stack,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { IoDocumentText } from "react-icons/io5";
import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import {
  deleteForm300,
  getAllForm300,
} from "../../../redux/slices/form300.slice";
import DataGridTable from "../dataGrid";
import moment from "moment";
import DateRangePicker from "../dateRangePicker";
import FilterDrawer from "../../layout/filterDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../../assets/icons/pencil.svg";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import dayjs from "dayjs";
import CustomizedDialog from "../Dialog";
import DeleteIcon from "../../../assets/icons/deleteRed.svg";
import { setSnackbar } from "../../../redux/slices/common.slice";

export default function Form300Logs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form300HistoryData, setForm300HistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [tokenPDF, setTokenPDF] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [dateRange_create, setDateRange_create] = useState(["", ""]);
  const [dateRange_update, setDateRange_update] = useState(["", ""]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState();
  const handleDownloadPDF = (pdfLink, fileName) => {
    // Create an anchor element and simulate a click to download the PDF
    const link = document.createElement("a");
    link.href = pdfLink + "?" + tokenPDF;
    link.target = "_blank"; // Opens the link in a new tab
    link.download = fileName; // Set a default file name, you might want to customize it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getAllForms300 = async () => {
    setIsLoading(true);

    let custom_filter = {};

    if (dateRange_create?.length > 0) {
      custom_filter.created_at__gte =
        dateRange_create[0] == ""
          ? undefined
          : dayjs(dateRange_create[0]).format("YYYY-MM-DD");
      custom_filter.created_at__lte =
        dateRange_create[1] == ""
          ? undefined
          : dayjs(dateRange_create[1]).add(1, "day").format("YYYY-MM-DD");
    }
    if (dateRange_update?.length > 0) {
      custom_filter.updated_at__gte =
        dateRange_update[0] == ""
          ? undefined
          : dayjs(dateRange_update[0]).format("YYYY-MM-DD");
      custom_filter.updated_at__lte =
        dateRange_update[1] == ""
          ? undefined
          : dayjs(dateRange_update[1]).add(1, "day").format("YYYY-MM-DD");
    }

    let response = await dispatch(
      getAllForm300({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        sort: sortKey,
        custom_filter: JSON.stringify(custom_filter),
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setForm300HistoryData(payload?.data);

      setTotalRow(payload?.total_object);
      setTokenPDF(payload?.token);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getAllForms300();
  }, [paginationModel, sortKey, dateRange_create, dateRange_update]);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const columns = [
    {
      field: "form_name",
      headerName: "Title",
      flex: 2, // Flex value of 2 (20% of the space if the total flex is 10)
      minWidth: 130,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "form_name")}
        >
          Title
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3, // Flex value of 4 (40% of the space if the total flex is 10)
      minWidth: 200, // Adjust minWidth as needed
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "description")}
        >
          Description
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 130, // Adjust minWidth as needed
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",

      // Flex value of 1 (10% of the space if the total flex is 10)
      width: 140, // Adjust minWidth as needed
      renderCell: DateCell,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1, // Remaining space

      renderCell: (params) => {
        const { form_link, id, form_name } = params.row;
        return (
          <>
            <Stack direction="row" spacing={2} alignItems="center">
              <Tooltip title="View">
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={() => navigate(`/reports/form300/${id}/view`)}
                >
                  <MdRemoveRedEye
                    size={24}
                    cursor={"pointer"}
                    color="#036185"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  sx={{ color: "#000000", padding: "0px" }}
                  onClick={() => navigate(`/reports/form300/${id}/edit`)}
                >
                  <img
                    alt="Edit Details"
                    src={Pencil}
                    width={24}
                    height={24}
                    className="actionIcon"
                    cursor={"pointer"}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download PDF">
                <IconButton
                  sx={{ color: "#000000", padding: "0" }}
                  onClick={() => handleDownloadPDF(form_link, form_name)}
                >
                  <IoDocumentText
                    size={24}
                    cursor={"pointer"}
                    color="#036185"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  sx={{ color: "#000000", padding: 0 }}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setSelectedReport(params.row);
                  }}
                >
                  <img
                    alt="Delete Details"
                    src={DeleteIcon}
                    width={24}
                    height={24}
                    className="actionIcon"
                    cursor={"pointer"}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
  ];
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        deleteForm300({ formId: selectedReport?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        setOpenDeleteModal(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Form Deleted succesfully",
          })
        );
        getAllForms300();
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="history">
        <Box className="content-header kb-list-header mb-4">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/reports");
            }}
            startIcon={<ArrowBackIcon />}
            className="primary-button back-button"
          >
            Back
          </Button>
          <Box variant="div" component="div" className="content-header-right">
            <Paper
              elevation={0}
              // component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine />}
              onClick={() => navigate("/reports/form300/select")}
            >
              Form 300
            </Button>
            <Button
              variant="contained"
              size="large"
              className="primary-button"
              endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
              disableRipple
              onClick={() => setOpenFilterDrawer(true)}
            >
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGridTable
            data={
              searchTerm
                ? form300HistoryData.filter((item) =>
                    item.form_name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                : form300HistoryData
            }
            columns={columns}
            setPaginationModel={setPaginationModel}
            isLoading={isLoading}
            paginationModel={paginationModel}
            totalRow={totalRow}
          />
        </Box>
      </div>
      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Created At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_create}
                onDateRangeChange={(e) => setDateRange_create(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Updated At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_update}
                onDateRangeChange={(e) => setDateRange_update(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
      </FilterDrawer>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedReport?.form_name} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={isLoading}
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={isLoading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
}
