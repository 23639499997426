import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import "../../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import {
  createUsersThunk,
  getOneUserThunk,
  updateUserThunk,
} from "../../../../redux/slices/tenant.slice";
import {
  VerticalAlignBottom,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import CustomizedDialog from "../../Dialog";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const CreateUsers = (
  { isOpenDrawer, fetchAllUsers, setIsOpenDrawer, drawerState, viewOpen },
  props
) => {
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    // "user_kb": [],
    // user_workspace: [],
    // user_role: 0,
    // status: true,
    emergency_phone_number: "",
    emergency_first_name: "",
    emergency_last_name: "",
    user_role: "",
    prefer_language: "",
    system_role: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const [userRole, setUserRole] = useState([]);

  useEffect(() => {
    if (viewOpen) {
      getUsersValues();
    }
    // eslint-disable-next-line
  }, [viewOpen]);

  // const fetchAllUserRole = async () => {
  //   try {
  //     let response = await dispatch(getAllUserRoleThunk());
  //     if (response.payload && response.payload.status) {
  //       setUserRole(response.payload.data);
  //     } else {
  //       await dispatch(
  //         setSnackbar({
  //           open: true,
  //           severity: "error",
  //           message: response.payload.message || "Internal server error",
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     await dispatch(
  //       setSnackbar({
  //         open: true,
  //         severity: "error",
  //         message: error || "Internal server error",
  //       })
  //     );
  //   }
  // };

  const getUsersValues = async () => {
    let response = await dispatch(getOneUserThunk(viewOpen));
    const { payload } = response;
    if (payload && payload?.status) {
      const data = {
        first_name: payload?.data?.first_name,
        last_name: payload?.data?.last_name,
        email: payload?.data?.email,
        password: payload?.data?.password,
        // "user_kb": [],
        // user_workspace: payload?.data?.user_workspace,
        // user_role: payload?.data?.user_role,
        id: payload?.data?.id,
        emergency_phone_number: payload?.data?.emergency_phone_number
          ? formatUSPhoneNumber(payload?.data?.emergency_phone_number)
          : "",
        emergency_first_name: payload?.data?.emergency_first_name,
        emergency_last_name: payload?.data?.emergency_last_name,
        user_role: payload?.data?.user_role || "",
        prefer_language: payload?.data?.prefer_language,
        system_role: payload?.data?.system_role === "admin" ? true : false,
        // status: payload?.data?.status,
      };

      setInitialValues(data);
      formik.setValues(data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const validationSchema1 = yup.object({
    first_name: yup.string().trim().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .email()
      .matches(/^(?!.*@[^,]*,)/)
      .required("Email is required"),
    emergency_phone_number: yup
      .string()
      .matches(
        /^(\(?\d{3}\)?[ ]*[\-]?[ ]*\d{3}[ ]*[\-]?[ ]*\d{4})$/,
        "Phone number must be exactly 10 digits"
      )
      .required("Please, Enter user emergency contact number"),
    user_role: yup.string().required("Please, Select user role"),
    prefer_language: yup.string().required("Please, Select user language"),
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      drawerState !== "edit"
        ? yup.object({
            first_name: yup.string().trim().required("First Name is required"),
            last_name: yup.string().required("Last Name is required"),
            email: yup
              .string()
              .email()
              .matches(/^(?!.*@[^,]*,)/)
              .required("Email is required"),
            password: yup
              .string()
              .min(6, "Minimum 6 character")
              .required("Password is required"),
            emergency_phone_number: yup
              .string()
              .matches(
                /^(\(?\d{3}\)?[ ]*[\-]?[ ]*\d{3}[ ]*[\-]?[ ]*\d{4})$/,
                "Phone number must be exactly 10 digits"
              )
              .required("Please, Enter user emergency contact number"),
            user_role: yup.string().required("Please, Select user role"),
            prefer_language: yup
              .string()
              .required("Please, Select user langauge"),

            // user_workspace: yup
            //   .array()
            //   .min(1, "Atleast 1 workspace is needed")
            //   .required("Workspace is required"),
            // user_role: yup.number().required("User Role is required"),
            // status: yup.boolean().required("Status is required"),
          })
        : validationSchema1,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        let response = await dispatch(
          drawerState === "edit"
            ? updateUserThunk({
                data: {
                  ...values,
                  system_role: values.system_role ? "admin" : "user",
                  emergency_phone_number: normalizeUSPhoneNumber(
                    values?.emergency_phone_number
                  ),
                },
                id: formik.values.id,
              })
            : createUsersThunk({
                ...values,
                system_role: values.system_role ? "admin" : "user",
                emergency_phone_number: normalizeUSPhoneNumber(
                  values?.emergency_phone_number
                ),
              })
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message:
                drawerState === "edit"
                  ? "Users Successfully Updated"
                  : "Users Successfully Added",
            })
          );
          setIsOpenDrawer(false);
          fetchAllUsers();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });

  const handleCloseDrawer = () => {
    formik.resetForm();
    setIsOpenDrawer(false);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Function --- Formate usa number
  function formatUSPhoneNumber(phoneNumber) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumber;
  }

  // Function --- Reverse usa number
  function normalizeUSPhoneNumber(formattedPhoneNumber) {
    const cleaned = ("" + formattedPhoneNumber).replace(/\D/g, "");
    return cleaned;
  }

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={
          drawerState === "view"
            ? "User Detail"
            : drawerState === "create"
            ? "Create User"
            : "Edit user"
        }
        open={isOpenDrawer || viewOpen}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    First Name
                  </Typography>
                  <TextField
                    disabled={drawerState === "view"}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter First Name"
                    size="small"
                    name="first_name"
                    {...formik.getFieldProps("first_name")}
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <div className="form-error-message">
                      {formik.errors.first_name}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={drawerState === "view"}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Last Name"
                    size="small"
                    name="last_name"
                    {...formik.getFieldProps("last_name")}
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <div className="form-error-message">
                      {formik.errors.last_name}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={drawerState === "view"}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Email"
                    size="small"
                    name="address1"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="form-error-message">
                      {formik.errors.email}
                    </div>
                  )}
                </Box>
              </Grid>
              {drawerState !== "edit" && drawerState !== "view" && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                    <Typography variant="body1" component="label">
                      Password
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Enter Password"
                      size="small"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      {...formik.getFieldProps("password")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility sx={{ fill: "#273167" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {formik.touched.password && formik.errors.password && (
                      <div className="form-error-message">
                        {formik.errors.password}
                      </div>
                    )}
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Emergency first name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={drawerState === "view"}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter emergency first name"
                    size="small"
                    name="emergency_first_name"
                    {...formik.getFieldProps("emergency_first_name")}
                  />
                  {/* {formik.touched.email && formik.errors.email && (
                    <div className="form-error-message">
                      {formik.errors.email}
                    </div>
                  )} */}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Emergency last name
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={drawerState === "view"}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter emergency last name"
                    size="small"
                    name="emergency_last_name"
                    {...formik.getFieldProps("emergency_last_name")}
                  />
                  {/* {formik.touched.email && formik.errors.email && (
                    <div className="form-error-message">
                      {formik.errors.email}
                    </div>
                  )} */}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Emergency contact number
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={drawerState === "view"}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter emergeny contact number"
                    size="small"
                    // type="number"
                    value={formik.values.emergency_phone_number}
                    name="emergency_phone_number"
                    {...formik.getFieldProps("emergency_phone_number")}
                    onChange={(e) => {
                      console.log("Run this funct uion");
                      formik.setFieldValue(
                        "emergency_phone_number",
                        formatUSPhoneNumber(e.target.value)
                      );
                    }}
                  />
                  {formik.touched.emergency_phone_number &&
                    formik.errors.emergency_phone_number && (
                      <div className="form-error-message">
                        {formik.errors.emergency_phone_number}
                      </div>
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    User role
                  </Typography>
                  <Select
                    disabled={drawerState === "view"}
                    fullWidth
                    labelId=""
                    placeholder="Select user role"
                    variant="outlined"
                    id="demo-simple-select-required"
                    value={formik?.values?.user_role}
                    size="small"
                    label=""
                    onChange={(e) => {
                      formik.setFieldValue("user_role", e.target.value);
                    }}
                  >
                    <MenuItem value={"onsite_engineer"}>
                      Onsite engineer
                    </MenuItem>
                    <MenuItem value={"supervisor"}>Supervisor</MenuItem>
                    <MenuItem value={"director"}>Director</MenuItem>
                  </Select>
                  {formik.touched.user_role && formik.errors.user_role && (
                    <div className="form-error-message">
                      {formik.errors.user_role}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    User Prefered Language
                  </Typography>
                  <Select
                    disabled={drawerState === "view"}
                    fullWidth
                    labelId=""
                    placeholder="Select user language"
                    variant="outlined"
                    id="demo-simple-select-required"
                    value={formik?.values?.prefer_language}
                    size="small"
                    label=""
                    onChange={(e) => {
                      formik.setFieldValue("prefer_language", e.target.value);
                    }}
                  >
                    <MenuItem value={"english"}>English</MenuItem>
                    <MenuItem value={"hindi"}>Hindi</MenuItem>
                  </Select>
                  {formik.touched.prefer_language &&
                    formik.errors.prefer_language && (
                      <div className="form-error-message">
                        {formik.errors.prefer_language}
                      </div>
                    )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isAdmin" // Unique name for this checkbox
                      checked={formik?.values?.system_role} // Assume `isAdmin` is a state variable that stores whether the user is an admin
                      onChange={(e) => {
                        formik.setFieldValue("system_role", e.target.checked);
                      }} // Update state on change
                    />
                  }
                  label="Is Admin?" // Label for the checkbox
                  labelPlacement="start"
                  sx={{ fontWeight: "bold", marginLeft: "2px" }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    User Role
                  </Typography>
                  <Autocomplete
                    id="user-role-select"
                    fullWidth
                    options={userRole}
                    autoHighlight
                    getOptionLabel={(option) => option.role_name}
                    value={
                      userRole.find(
                        (role) => role.id === formik.values.user_role
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "user_role",
                        newValue ? newValue.id : ""
                      ); // set the user_role value
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.role_name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id="outlined-basic"
                        // label="User Role"
                        variant="outlined"
                        placeholder="Select User Role"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  {formik.touched.user_role && formik.errors.user_role && (
                    <div className="form-error-message">
                      {formik.errors.user_role}
                    </div>
                  )}
                </Box>
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Workspace
                  </Typography>
                  <Select
                    isMulti
                    name="user_workspace"
                    options={workspace.map((option) => ({
                      value: option.id,
                      label: option.branch_name,
                    }))}
                    className="basic-multi-select"
                    value={workspace
                      .filter((option) =>
                        formik.values.user_workspace?.includes(option?.id)
                      )
                      .map((option) => ({
                        value: option.id,
                        label: option.branch_name,
                      }))}
                    onChange={(selectedOptions) => {
                      formik.setFieldValue(
                        "user_workspace",
                        selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : []
                      );
                    }}
                    classNamePrefix="select"
                    placeholder={"Select Workspace..."}
                    components={{
                      IndicatorSeparator: null,
                    }}
                  />
                  {formik.touched.user_workspace &&
                    formik.errors.user_workspace && (
                      <div className="form-error-message">
                        {formik.errors.user_workspace}
                      </div>
                    )}
                </Box>
              </Grid> */}
              {/* <Grid item xs={12}>
                <Box variant="div" component="div">
                  <ToggleButtonGroup
                    value={formik.values.status}
                    name="status"
                    exclusive
                    onChange={(event, flag) => {
                      formik.setFieldValue("status", flag);
                    }}
                    aria-label="text alignment"
                    // {...formik.getFieldProps('flag')}
                  >
                    <ToggleButton
                      value={true}
                      name="status"
                      aria-label="left aligned"
                    >
                      Active
                    </ToggleButton>
                    <ToggleButton
                      value={false}
                      name="status"
                      aria-label="centered"
                    >
                      Inactive
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {formik.touched.status && formik.errors.status && (
                    <div className="form-error-message">
                      {formik.errors.status}
                    </div>
                  )}
                </Box>
              </Grid> */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {(drawerState !== "view" || drawerState === "edit") && (
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          )}
        </DialogActions>
      </CustomizedDialog>
      {/* <Drawer
        className="side-drawer"
        anchor="right"
        open={isOpenDrawer || viewOpen}
        onClose={() => {
          handleCloseDrawer();
        }}
      > */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="sidebar-wrapper p-3"
      >
        <Typography variant="h4" className="text-white">
          {drawerState === "view"
            ? "User Detail"
            : drawerState === "create"
            ? "Create User"
            : "Edit user"}
        </Typography>
        <Box
          className="close-btn"
          onClick={handleCloseDrawer}
          sx={{ cursor: "pointer" }}
        >
          <RiCloseLine fill="#ffffff" />
        </Box>
      </Stack>

      <Box variant="div" component="div" className="form-wrap p-3"></Box>

      {/* </Drawer> */}
    </>
  );
};

export default CreateUsers;
