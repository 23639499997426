import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/apiUrl.constant";
import axios from "axios";

export const setSnackbar = createAsyncThunk("setSnackbar", async (data) => {
  return data;
});
export const setNotificationReportId = createAsyncThunk(
  "setNotificationReportId",
  async (data) => {
    return data;
  }
);

export const userActivityThunk = createAsyncThunk(
  "userActivity",
  async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.USER_ACTIVITY}`,
        page,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// User slice
const commonSlice = createSlice({
  name: "user",
  initialState: {
    snackbar: {
      open: false,
      severity: "success",
      message: "",
    },
    reportId: null,
  },
  extraReducers: (builder) => {
    builder.addCase(setSnackbar.fulfilled, (state, action) => {
      state.snackbar.open = action.payload.open;
      state.snackbar.message = action.payload.message;
      state.snackbar.severity = action.payload.severity;
    });
    builder.addCase(setNotificationReportId.fulfilled, (state, action) => {
      state.reportId = action.payload;
    });
  },
});

export default commonSlice.reducer;
