import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Modal,
  DialogContent,
  CardActions,
  IconButton,
} from "@mui/material";

import "../../../../styles/externalAssistant.scss";

import { useLocation, useNavigate } from "react-router-dom";

import { PAGE_URL } from "../../../../constants/pageUrl.constant";

import AddIcon from "../../../../assets/icons/add.svg";
import CloseIcon from "@mui/icons-material/Close";
import { RiCloseLine } from "react-icons/ri";
import { IoDocumentOutline } from "react-icons/io5";
import { IoMdDocument } from "react-icons/io";
import CustomizedDialog from "../../Dialog";
import FormRenderer from "./formRenderer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import { getAllForm, getFormTypes } from "../../../../redux/slices/forms.slice";
import Select from "react-select";
const steps = ["Basic", "Instructions"];

const options = [
  { value: "option1", label: "Option 1" },
  { value: "option2", label: "Option 2" },
  { value: "option3", label: "Option 3" },
  { value: "option4", label: "Option 4" },
];

const customStyles = {
  control: (base) => ({
    ...base,
    border: "1px solid #036185",
    borderRadius: 8,
    padding: 4,
    boxShadow: "none",
    width: "40%",
    "&:hover": {
      borderColor: "#036185",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    marginTop: 4,
    zIndex: 100,
    width: "40%",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#e3f2fd" : "white",
    color: "#036185",
    "&:active": {
      backgroundColor: "#bbdefb",
    },
  }),
};
const CreateForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(state?.title || "");
  const [description, setDescription] = useState(state?.description || "");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedForm, setSelectedForm] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [formTemplates, setFormTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedType, setSelectedType] = useState({
    label: "All",
    value: "all",
  });
  const [formTypes, setFormTypes] = useState([]);
  const dispatch = useDispatch();

  const fetchAllFormTypes = async () => {
    try {
      const response = await dispatch(getFormTypes());
      const { payload } = response;
      if (payload?.status) {
        let AllType = [{ label: "All", value: "all" }];

        setFormTypes([
          ...AllType,
          ...payload?.data?.map((item) => ({
            label: item.name,
            value: item.type,
          })),
        ]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    fetchAllFormTypes();
  }, []);

  const fetchAllTemplateForms = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        getAllForm({
          custom_filter: JSON.stringify({ is_template: true }),
          list: "all",
        })
      );
      const { payload } = response;
      if (payload?.status) {
        if (selectedType?.value === "all") setFormTemplates(payload?.data);
        else {
          setFormTemplates(
            payload?.data?.filter((item) => item.type === selectedType?.value)
          );
        }
      }
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    fetchAllTemplateForms();
  }, [selectedType]);

  const BootstrapInput = {
    marginBottom: "20px",
    "& label": {
      width: "100%",
      "&.Mui-focused": {
        color: "#273167",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& .Mui-disabled": {
        backgroundColor: "rgba(39, 49, 103, 0.1)",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#273167 !important",
          borderWidth: "1px !important",
        },
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#273167 !important",
          borderWidth: "1px !important",
        },
      },
    },
  };

  const handleChange = (option) => {
    setSelectedType(option);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    navigate(PAGE_URL.FORMBUILDER);
  };

  const handleNext = () => {
    if (!title) {
      setTitleError("Title is Required!");
      return;
    }
    if (!description) {
      setDescriptionError("Description is Required!");
      return;
    }
    setActiveStep((prev) => prev + 1);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div className="mt mx-auto main-div-assistant">
          <Stepper
            sx={{
              paddingBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
            flexDirection={{ sm: "column !important" }}
            activeStep={activeStep}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  className={`step-lable ${
                    activeStep === index && activeStep !== 2
                      ? "step-lable-before"
                      : ""
                  }`}
                  key={label}
                  {...stepProps}
                  sx={{
                    backgroundColor:
                      activeStep === index
                        ? "#CA9688"
                        : activeStep > index
                        ? "#fff"
                        : "#fff",
                  }}
                >
                  <StepLabel
                    className={`${
                      activeStep === index
                        ? "step-circle active-step"
                        : "inactive-text"
                    } ${activeStep > index ? "step-circle" : ""} `}
                    {...labelProps}
                    sx={{ color: "white !important" }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={11}
            xl={11}
            sx={{ paddingX: "40px !important" }}
          >
            <hr style={{ marginTop: "0px", marginBottom: "40px" }}></hr>
          </Grid>
          <>
            {activeStep + 1 === 1 ? (
              <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                  <p
                    style={{
                      paddingBottom: "15px",
                      color: "#0D333F",
                      fontWeight: 500,
                      fontSize: "15px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Give a public name and description to the Template.
                  </p>
                  <Grid item className="margin-bottom-input">
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Typography
                        color="#0D333F"
                        variant="body1"
                        component="label"
                      >
                        Title
                      </Typography>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label=""
                        variant="outlined"
                        placeholder="Enter Name"
                        size="small"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setTitleError("");
                        }}
                      />
                      {titleError && (
                        <div className="form-error-message">{titleError}</div>
                      )}
                    </Box>
                  </Grid>
                  <Grid item className="margin-bottom-input">
                    <Box variant="div" component="div" sx={BootstrapInput}>
                      <Typography
                        variant="body1"
                        component="label"
                        color="#0D333F"
                      >
                        Description
                      </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Enter Description"
                        size="small"
                        name="description"
                        rows={4}
                        multiline
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setDescriptionError("");
                        }}
                      />
                      {descriptionError && (
                        <div className="form-error-message">
                          {descriptionError}
                        </div>
                      )}
                      <Typography
                        variant="subtitle1"
                        sx={{ fontSize: "14px", color: "#989898" }}
                      >
                        The description can help users know what the template is
                        for.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ) : activeStep + 1 === 2 ? (
              <>
                <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyItems="center"
                      px={{ md: 6, sm: 0 }}
                      pt={2}
                    >
                      <Grid item xs={12} md={12} lg={12}>
                        <p
                          style={{
                            paddingBottom: "15px",
                            color: "#0D333F",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          This is where you create your Form Templates by
                          selecting below options.
                        </p>
                      </Grid>
                    </Grid>
                    <Grid item className="margin-bottom-input">
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Grid
                          container
                          spacing={4}
                          alignItems="center"
                          justifyItems="center"
                          pt={4}
                          px={{ sm: 0, md: 6 }}
                        >
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Card
                              sx={{
                                height: "100%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                              className={`assistantCard-hover "custom-active"
                                    
                                }`}
                              onClick={() =>
                                navigate(
                                  `/settings/formbuilder/custom/create`,
                                  {
                                    state: {
                                      title,
                                      description,
                                    },
                                  }
                                )
                              }
                            >
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <img
                                    alt="Add"
                                    src={AddIcon}
                                    width={24}
                                    height={24}
                                  />
                                  <Typography
                                    sx={{
                                      marginLeft: "10px",
                                      fontSize: "18px",
                                      color: "#036185",
                                    }}
                                    variant="h6"
                                  >
                                    Custom Set Up
                                  </Typography>
                                </Box>
                                <hr style={{ color: "#EBEBEB", opacity: 1 }} />
                                <Typography
                                  sx={{ fontSize: "14px", color: "grey" }}
                                  variant="h6"
                                >
                                  Create your Template with guided customization
                                  for your unique goals.
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Card
                              sx={{
                                minWidth: 200,
                                height: "100%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                              className={`assistantCard-hover "custom-active"
                                   
                                }`}
                              onClick={() => {
                                setIsModalOpen(true);
                              }}
                            >
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <IoDocumentOutline
                                    style={{
                                      color: "#036185",
                                      height: "24px",
                                      width: "24px",
                                    }}
                                  />

                                  <Typography
                                    sx={{
                                      marginLeft: "10px",
                                      fontSize: "18px",
                                      color: "#036185",
                                    }}
                                    variant="h6"
                                  >
                                    Use Template Library
                                  </Typography>
                                </Box>
                                <hr style={{ color: "#EBEBEB", opacity: 1 }} />
                                <Typography
                                  sx={{ fontSize: "14px", color: "grey" }}
                                  variant="h6"
                                >
                                  Begin with a pre-made starting point, then
                                  customize from there.
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
            <Box
              flexDirection={{ sm: "column", md: "row" }}
              sx={{
                display: "flex",
                pt: 2,
                justifyContent: "center",
              }}
              className="Create-assitant-media"
            >
              {activeStep !== 0 && (
                <Button
                  onClick={handleCancel}
                  sx={{
                    padding: "8px 32px !important",
                    borderRadius: "5px",
                    minWidth: "auto",
                    backgroundColor: "#fff",
                    color: "#036185 !important",
                    border: 1,
                    borderColor: "#273167",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#273167",
                      border: 1,
                      borderColor: "#273167",
                    },
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={activeStep === 0 ? handleCancel : handleBack}
                sx={{
                  padding: "8px 32px !important",
                  borderRadius: "5px",
                  marginLeft: "15px",
                  minWidth: "auto",
                  backgroundColor: "#fff",
                  color: "#036185",
                  border: 1,
                  borderColor: "#273167",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#036185",
                    border: 1,
                    borderColor: "#036185",
                  },
                }}
              >
                {activeStep === 0 ? "Cancel" : "Back"}
              </Button>
              {activeStep === 0 && (
                <Button
                  disabled={isLoading}
                  sx={{
                    padding: "8px 32px !important",
                    borderRadius: "5px",
                    marginLeft: "15px",
                    backgroundColor: "#036185 ",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff !important",
                      color: "#273167 !important",
                      border: 1,
                      borderColor: "#036185",
                    },
                  }}
                  className={`${
                    activeStep + 1 === 2 || isLoading ? "disablestep" : ""
                  }`}
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </Box>
          </>
        </div>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Box
          className="default-assitant-media"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "20px 30px 30px 30px",
            width: "1000px",
            height: "calc(100vh - 120px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "20px",
            }}
          >
            <Typography
              component="div"
              color="#0D333F"
              sx={{ fontSize: "18px" }}
            >
              Choose Template
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => setIsModalOpen(false)}
              sx={{
                position: "absolute",
                right: 20,
                top: 12,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Select
            value={selectedType}
            onChange={handleChange}
            options={formTypes}
            styles={customStyles}
            placeholder="Select an option"
          />
          <Box
            sx={{
              maxHeight: "calc(100vh - 270px)",
              overflowY: "auto",
              padding: "4px",
            }}
            mt={1}
            className="table-container"
          >
            <Grid container spacing={4}>
              {!formTemplates.length && (
                <Box
                  className="center"
                  sx={{ margin: "auto", height: "200px" }}
                >
                  No Form Templates Found
                </Box>
              )}
              {formTemplates?.map((form, i) => {
                return (
                  <Grid key={form.id} item xs={12} sm={6} md={6} lg={6}>
                    <Card
                      sx={{
                        maxHeight: 215,
                        height: "100%",
                        position: "relative",
                        cursor: "pointer",
                        boxShadow: " 0px 2px 27px 0px #0000001F",
                      }}
                      className={"custom-active"}
                      onClick={() => {
                        navigate(
                          `/settings/formbuilder/${form.type}/create?formId=${form.id}`,
                          {
                            state: {
                              title,
                              description,
                            },
                          }
                        );
                        setIsModalOpen(false);
                      }}
                    >
                      <CardContent
                        sx={{ padding: "16px !important", height: "125px" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            paddingBottom: "10px",
                          }}
                        >
                          <IoMdDocument />
                          <Typography
                            sx={{
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                            variant="h6"
                          >
                            {`${
                              form.title.length > 50
                                ? form.title.substring(0, 50) + "..."
                                : form.title
                            }`}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "grey",
                          }}
                          variant="h6"
                        >
                          {`${
                            form.description.length > 130
                              ? form.description.substring(0, 130) + "..."
                              : form.description
                          }`}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Box
                          sx={{
                            marginLeft: "auto",
                          }}
                        >
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedForm(form);
                              setOpenPreview(true);
                            }}
                            size="small"
                            sx={{
                              color: "white",
                              padding: "8px 10px",
                              mt: 1,
                              // fontSize: "14px !important",
                            }}
                            className="primary-button"
                            endIcon={<VisibilityIcon />}
                          >
                            Preview
                          </Button>
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Modal>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Preview Form"}
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
      >
        <DialogContent>
          <FormRenderer formId={selectedForm?.id} />
        </DialogContent>
      </CustomizedDialog>
    </>
  );
};

export default CreateForm;
