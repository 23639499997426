import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Select,
  MenuItem,
  Typography,
  Chip,
  CircularProgress,
  Checkbox,
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import DeleteIcon from "../../../assets/icons/deleteRed.svg";
import Pencil from "../../../assets/icons/pencil.svg";
import { MdRemoveRedEye } from "react-icons/md";
import DateRangePicker from "../dateRangePicker";
import "../../../styles/reports.scss";
import { setSnackbar } from "../../../redux/slices/common.slice";
import {
  getAllTenantReport,
  getAllUsersThunk,
} from "../../../redux/slices/tenant.slice";
import { useDispatch } from "react-redux";
import moment from "moment";
import CreateReport from "../createReport";
import DeleteConfigureAction from "../settings/ConfigureAction/DeleteConfigureAction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  REPORT_OPTION,
  CLASSIFICATION_OPTION,
  INJURY_OPTION,
} from "../../../constants/report.constant";
import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import FilterDrawer from "../../layout/filterDrawer";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Selectt from "react-select";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { JobTitle, Status } from "../../../utils/icon/CommonFunctions";
const TableStyle = {
  maxHeight: " calc(100vh - 200px)", // Set the height limit for the container
  overflowY: "auto",
  "& .MuiTable-root": {
    "& .MuiTableHead-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          backgroundColor: "#64bcd2",
          color: "#FFFFFF",
          fontSize: "15px",
          "&:last-child": {
            borderBottom: "none",
          },
          "& .MuiTableSortLabel-root": {
            "& svg": {
              fill: "#FFFFFF",
            },
          },
        },
      },
    },

    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          padding: "12px",
          borderColor: "rgba(224, 224, 224, 1)",
          "& .public-status": {
            backgroundColor: "#6EC531",
          },
          "& .private-status": {
            backgroundColor: "#273167",
          },
          "&:focus": {
            outline: "none !important",
          },
        },
        "&:hover": {
          backgroundColor: "rgb(255 115 100 / 5%)",
        },
      },
    },
  },

  "& .MuiTableContainer-root": {
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
    },
  },
};

const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const ReportSelectionForm300 = () => {
  const [reportTypeFilter, setReportTypeFilter] = useState("all");
  const [userTypeFilter, setUserTypeFilter] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [dateRange, setDateRange] = useState(["", ""]); // Initial date range
  const [reportStatus, setReportStatus] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [userData, setUserData] = useState([]);
  const [reportLayout, setReportLayout] = useState(false);
  const [drawerState, setDrawerState] = useState("edit");
  const [selecteduserData, setSelectedUserData] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [imageToken, setImageToken] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [sortKey, setSortKey] = useState();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedIds(reportData?.map((item) => item.id));
  }, [reportData]);
  const handleCheckboxChange = (rowId) => {
    setSelectedIds(
      (prevSelectedIds) =>
        prevSelectedIds.includes(rowId)
          ? prevSelectedIds.filter((id) => id !== rowId) // Unselect the row
          : [...prevSelectedIds, rowId] // Select the row
    );
  };
  // Report option
  const ReportOption = REPORT_OPTION;

  // Classfication option
  const ClassficationOption = CLASSIFICATION_OPTION;

  // Injury option
  const InjuryOption = INJURY_OPTION;

  const dispatch = useDispatch();

  const fetchAllReport = async () => {
    setIsLoading(true);

    let custom_filter = {};

    if (reportTypeFilter.length > 0) {
      custom_filter.type__in = ["hazard", "incident"];
    }

    if (userTypeFilter.length > 0) {
      custom_filter.user__id__in = userTypeFilter.map((item) => item.value);
    }
    if (dateRange?.length > 0) {
      custom_filter.report_date__gte =
        dateRange[0] == ""
          ? undefined
          : dayjs(dateRange[0]).format("YYYY-MM-DD");
      custom_filter.report_date__lte =
        dateRange[1] == ""
          ? undefined
          : dayjs(dateRange[1]).add(1, "day").format("YYYY-MM-DD");
    }
    let send_payload = {
      page_number: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      status: reportStatus == "All" ? undefined : reportStatus,
      sort: sortKey,
      custom_filter: JSON.stringify(custom_filter),
    };

    let response = await dispatch(getAllTenantReport(send_payload));
    const { payload } = response;
    if (payload?.status) {
      setReportData(payload?.data);
      setTotalRow(payload?.total_object);
      setImageToken(payload?.token);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  const fetchAllUser = async () => {
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 1000,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      const tempData = payload.data?.map((item) => ({
        label: item.first_name + " " + item.last_name,
        value: item.id,
      }));
      setUserData(tempData);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  useEffect(() => {
    fetchAllReport();
  }, [
    paginationModel,
    reportTypeFilter,
    userTypeFilter,
    dateRange,
    rowsPerPage,
    page,
    reportStatus,
    sortKey,
  ]);

  const handleSorting = (e, key) => {
    console.log("sortinggg", key);
    if (key) {
      if (sortKey && sortKey.startsWith("-") && sortKey.substring(1) === key) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState("en");

    const changeLanguage = (e, lng) => {
      setLanguage(lng);
      setOpen(true);
    };

    const OtherDetails = [
      {
        label: i18n.getFixedT(language)("reportFormLabels.title"),
        value: row?.title,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.description"),
        value: row?.description,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.injuryDescription"),
        value: row?.injury_description,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.classifyOfCase"),
        value: row?.classify_of_case == null ? "-" : row?.classify_of_case,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.illness"),
        value: row?.illness == null ? "-" : row?.illness,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.awayWorkDays"),
        value: row?.number_of_days_injured?.away_work_days || 0,
      },
      {
        label: i18n.getFixedT(language)("reportFormLabels.jobTransferDays"),
        value: row?.number_of_days_injured?.job_transfer_days || 0,
      },
    ];

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell className="table-report-data-cell">
            {/* chatgpt here */}

            <Checkbox
              checked={selectedIds.includes(row.id)}
              onChange={() => handleCheckboxChange(row.id)}
              inputProps={{ "aria-label": `Select row ${row.id}` }}
            />
          </TableCell>
          <TableCell className="table-report-data-cell">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell
            component="th"
            scope="row"
            className="table-report-data-cell"
          >
            {row?.project?.projectid}
          </TableCell>

          <TableCell align="teft" className="table-report-data-cell">
            {row?.user?.user_role != null
              ? JobTitle[row?.user?.user_role]
              : "-"}
          </TableCell>

          <TableCell align="teft" className="table-report-data-cell">
            <Chip label={row?.report_type} color="primary" variant="outlined" />
          </TableCell>

          <TableCell
            align="left"
            className="table-report-data-cell"
          >{`${row?.user?.first_name} ${row?.user?.last_name}`}</TableCell>

          {/* <TableCell align="left" className="table-report-data-cell">
            {moment(row?.report_date).format("DD-MM-YYYY")}
          </TableCell> */}

          {/* <TableCell align="left" className="table-report-data-cell">
            {moment(row?.updated_at).format("DD-MM-YYYY")}
          </TableCell> */}
          <TableCell align="left" className="table-report-data-cell">
            {row.assigned_to
              ? `${row?.assigned_to?.first_name} ${row?.assigned_to?.last_name}`
              : "-"}
          </TableCell>

          {/* <TableCell align="left" className="table-report-data-cell">
            {!row?.event_occurred ? "-" : row?.event_occurred}
          </TableCell> */}

          <TableCell align="left" className="table-report-data-cell">
            <Tooltip title={Status[row?.status].hover}>
              <Chip
                label={Status[row?.status].label}
                style={{
                  backgroundColor: Status[row?.status].color,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </TableCell>

          <TableCell
            align="left"
            className="table-report-data-cell"
            sx={{
              position: "sticky",
              right: 0,
              background: "#fff", // Ensure the background is not transparent
              zIndex: 1, // Keep it on top while scrolling
              boxShadow: "inset 3px 0 3px -3px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Tooltip title="View">
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={() => {
                    setReportLayout(true);
                    setDrawerState("view");
                    setSelectedUserData(row);
                  }}
                >
                  <MdRemoveRedEye
                    size={24}
                    cursor={"pointer"}
                    color="#036185"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  sx={{ color: "#000000", padding: "0px" }}
                  onClick={() => {
                    setReportLayout(true);
                    setDrawerState("edit");
                    setSelectedUserData(row);
                  }}
                >
                  <img
                    alt="Edit Details"
                    src={Pencil}
                    width={24}
                    height={24}
                    className="actionIcon"
                    cursor={"pointer"}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  sx={{ color: "#000000", padding: "0" }}
                  onClick={() => {
                    setIsDelete(true);
                    setSelectedUserData(row);
                  }}
                >
                  <img
                    alt="Delete Details"
                    src={DeleteIcon}
                    width={24}
                    height={24}
                    className="actionIcon"
                    cursor={"pointer"}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={10}
            className="form-other-information-cell"
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box display="flex">
                <Box sx={{ margin: 1 }} className="other-info-div">
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      component="div"
                      style={{ fontWeight: 700 }}
                    >
                      {i18n.getFixedT(language)("OtherInfo")}
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={language}
                      exclusive
                      onChange={changeLanguage}
                      aria-label={"languageToggle"}
                      className="toggle-language-btn"
                    >
                      <ToggleButton
                        value="en"
                        className={
                          language === "en" ? "selected" : "nonSelected"
                        }
                      >
                        {i18n.getFixedT(language)("english")}
                      </ToggleButton>
                      <ToggleButton
                        value="hi"
                        className={
                          language === "hi" ? "selected" : "nonSelected"
                        }
                      >
                        {i18n.getFixedT(language)("hindi")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <hr></hr>
                  <ul style={{ width: "700px", paddingLeft: "0px" }}>
                    {OtherDetails.map((details) => (
                      <>
                        <li
                          className="other-info-list"
                          style={{ marginTop: 4 }}
                        >
                          <Typography
                            variant="subtitle1"
                            className="other-info-list-title"
                            width={"100%"}
                            fontWeight={400}
                          >
                            {details.label}
                          </Typography>{" "}
                          <Typography
                            variant="subtitle1"
                            width={"100%"}
                            marginLeft={"5px"}
                            className="other-info-list-description"
                          >
                            {details.value}
                          </Typography>{" "}
                        </li>
                        <hr className="other-info-list-divider"></hr>
                      </>
                    ))}
                  </ul>
                </Box>
                <Box
                  component="img"
                  alt="Descriptive Alt Text" // Replace with your alt text for accessibility
                  src={`${row?.image[0]}?${imageToken}`} // Replace with your image URL
                  sx={{
                    width: 300, // Set the width of the image box
                    height: 300, // Set the height of the image box
                    objectFit: "cover", // This makes the image cover the box without distortion
                    margin: 1,
                    borderRadius: 2,
                  }}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return (
    <>
      <Box className="content-header kb-list-header mb-4 form300-selection">
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            navigate("/reports");
          }}
          startIcon={<ArrowBackIcon />}
          className="primary-button back-button"
        >
          Back
        </Button>
        <Box variant="div" component="div" className="content-header-right">
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              localStorage.setItem(
                "selectedReports",
                JSON.stringify(selectedIds)
              );
              navigate("/reports/form300/create");
            }}
            disabled={reportData?.length === 0}
          >
            Generate Form 300
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary-button"
            endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
            disableRipple
            onClick={() => setOpenFilterDrawer(true)}
          >
            Filters
          </Button>
        </Box>
      </Box>

      <Box component={Paper}>
        <TableContainer sx={TableStyle} className="table-container">
          <Table>
            <TableHead sx={{ position: "sticky", top: 0, zIndex: 1000 }}>
              <TableRow>
                <TableCell width={20} />
                <TableCell width={20} />
                <TableCell
                  onClick={(e) => handleSorting(e, "project_id")}
                  className="cursor-pointer"
                >
                  Project ID <SwapVertIcon style={{ marginLeft: 4 }} />
                </TableCell>
                <TableCell
                  align="left"
                  onClick={(e) => handleSorting(e, "user__user_role")}
                  className="cursor-pointer"
                >
                  Job title <SwapVertIcon style={{ marginLeft: 4 }} />
                </TableCell>
                <TableCell
                  align="left"
                  onClick={(e) => handleSorting(e, "type")}
                  className="cursor-pointer"
                >
                  Report Type <SwapVertIcon style={{ marginLeft: 4 }} />
                </TableCell>
                <TableCell
                  align="left"
                  onClick={(e) => handleSorting(e, "user__first_name")}
                  className="cursor-pointer"
                >
                  Report By <SwapVertIcon style={{ marginLeft: 4 }} />
                </TableCell>
                {/* <TableCell
                  align="left"
                  onClick={(e) => handleSorting(e, "report_date")}
                  className="cursor-pointer"
                >
                  Injury Date <SwapVertIcon style={{ marginLeft: 4 }} />
                </TableCell> */}
                <TableCell
                  align="left"
                  onClick={(e) => handleSorting(e, "assigned_to")}
                  className="cursor-pointer"
                >
                  Assigned To <SwapVertIcon style={{ marginLeft: 4 }} />
                </TableCell>
                {/* <TableCell
                  align="left"
                  onClick={(e) => handleSorting(e, "event_occurred")}
                  className="cursor-pointer"
                >
                  Where event occured <SwapVertIcon style={{ marginLeft: 4 }} />
                </TableCell> */}
                <TableCell
                  align="left"
                  onClick={(e) => handleSorting(e, "status")}
                  className="cursor-pointer"
                >
                  Report Status <SwapVertIcon style={{ marginLeft: 4 }} />
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    position: "sticky",
                    right: 0,
                    background: "#fff", // Ensure the background is not transparent
                    zIndex: 1, // To make sure it stays above other content while scrolling
                    boxShadow: "inset 3px 0 3px -3px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <>
                  <TableRow
                    style={{ paddingTop: "12px !important", paddingBottom: 12 }}
                  >
                    <TableCell
                      style={{ borderBottom: 0 }}
                      colSpan={10}
                      align="center"
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {reportData?.length === 0 ? (
                    <>
                      <TableRow>
                        <TableCell
                          colSpan={10}
                          style={{ paddingTop: 10 }}
                          align="center"
                        >
                          <Typography variant="label" align="center">
                            No Records Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      {reportData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <Row key={row.name} row={row} />
                        ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!isLoading && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 40]}
            component="div"
            count={totalRow}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
      {reportLayout && (
        <CreateReport
          isLayout={reportLayout}
          setLayout={setReportLayout}
          drawerState={drawerState}
          values={selecteduserData}
          fetchAllReport={fetchAllReport}
        />
      )}

      {isDelete && (
        <DeleteConfigureAction
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          selectedRowData={selecteduserData}
          fetchAllKnowledgeBase={fetchAllReport}
          kbmodule={"Report"}
          isLoading={isLoading}
        />
      )}

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Injury Date
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Report Status
          </Typography>
          <Select
            sx={customStyles}
            name="status"
            className="basic-multi-select"
            style={{ width: "100%" }}
            onChange={(event) => {
              setReportStatus(event.target.value);
            }}
            value={reportStatus}
            classNamePrefix="select"
            components={{
              IndicatorSeparator: () => null,
            }}
            size="small"
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"PENDING"}>Pending</MenuItem>
            <MenuItem value="IN_REVIEW">In Review</MenuItem>
            <MenuItem value={"COMPLETED"}>Completed</MenuItem>
          </Select>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Users
          </Typography>
          <Selectt
            styles={customStyles}
            isMulti
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={userData}
            value={userTypeFilter}
            onChange={(e) => {
              setUserTypeFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Users..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </Box>
      </FilterDrawer>
    </>
  );
};

export default ReportSelectionForm300;
