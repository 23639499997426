import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { getServiceUnitById } from "../../../redux/slices/serviceUnit.slice";
import { setSnackbar } from "../../../redux/slices/common.slice";
import ActionList from "./actionList";
import TaskList from "./taskList";
const TabOptions = ["General Information", "Inspection", "Tasks"];
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ViewServiceUnit() {
  const location = useLocation();
  const state = location.state;

  const [value, setValue] = useState(state?.tab || 0);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [type, setType] = useState("");
  const [token, setToken] = useState("");
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serviceUnitId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchServiceUnitDetailsById = async () => {
    setLoading(true);

    try {
      let response = await dispatch(
        getServiceUnitById({
          id: serviceUnitId,
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setDisplayName(payload?.data?.name);
        setToken(payload?.token);
        setSelectedQuestions(payload?.data?.service_unit_details_json);
        setType(payload?.data?.type?.name);
        setAttachments(payload?.data?.documents);
        setProfilePicFile(
          `${payload?.data?.profile_picture?.img_url}?${payload?.token}`
        );
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Internal server error",
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchServiceUnitDetailsById();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} mb={2}>
        <Tabs
          value={value}
          className="configure-action-tab"
          onChange={handleChange}
          aria-label="Free form assistants"
        >
          {TabOptions?.map((item, index) => (
            <Tab label={item} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {loading && (
        <Box
          sx={{
            height: "calc(100vh - 200px)",
          }}
          className="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && value === 0 && (
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Box
              className="box-container"
              sx={{ height: "calc(100vh - 180px)" }}
            >
              <Stack alignItems="center" spacing={2}>
                <Typography
                  sx={{
                    color: "#00446B !important",
                    fontSize: "18px !important",
                    fontWeight: "500 !important",
                  }}
                  mb={2}
                >
                  Profile Picture
                </Typography>
                <Box sx={{ position: "relative", display: "inline-block" }}>
                  <Avatar
                    src={`${profilePicFile}`}
                    sx={{
                      width: 120,
                      height: 120,
                      border: "2px solid #ddd",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                      backgroundColor: "#64bcd2",
                    }}
                  />
                </Box>
                <Divider />

                {/* Upload Attachments Button */}

                {/* List of Uploaded Attachments */}
                {attachments?.length > 0 && (
                  <>
                    <Typography
                      sx={{
                        color: "#00446B !important",
                        fontSize: "18px !important",
                        fontWeight: "500 !important",
                      }}
                    >
                      Documents
                    </Typography>
                    <List sx={{ marginTop: "0px !important" }}>
                      {attachments.map((file, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <InsertDriveFileIcon />
                          </ListItemIcon>
                          {file.url ? (
                            <Link to={`${file.url}?${token}`} target="_blank">
                              <ListItemText primary={file.file_name} />
                            </Link>
                          ) : (
                            <ListItemText primary={file.file_name} />
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box
              className="box-container"
              sx={{ height: "calc(100vh - 180px)" }}
            >
              {/* <Box sx={{ flex: 1, overflowY: "auto", padding: "24px" }}> */}
              <Grid container spacing={2} className="form-disabled">
                <Grid item xs={6}>
                  <Typography variant="body1" component="label">
                    Display Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Enter Display Name"
                    value={displayName}
                    name={"displayName"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" component="label">
                    Service Unit Type
                  </Typography>

                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Enter Type"
                    value={type}
                    name={"type"}
                  />
                </Grid>
                {selectedQuestions?.map((question) => (
                  <Grid item xs={question.width} key={question.id}>
                    <Typography variant="body1" component="label">
                      {question.label}
                    </Typography>

                    {question.type === "Text Field" && (
                      <>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Your answer here"
                          required={question.required}
                          name={question.id}
                          value={question.answer || ""}
                        />
                      </>
                    )}

                    {question.type === "Date" && (
                      <>
                        <TextField
                          type="date"
                          fullWidth
                          variant="outlined"
                          size="small"
                          required={question.required}
                          value={question.answer || ""}
                          name={question.id}
                        />
                      </>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}

      {!loading && value === 1 && <ActionList />}
      {!loading && value === 2 && <TaskList />}
    </Box>
  );
}
