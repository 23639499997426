import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const RouteProtection = ({ children, pageAccess }) => {
  let isAutheticated;
  let isAuthorized = true;
  try {
    const user = useSelector((state) => state.user);
    console.log(user);
    if (user?.data && user?.data?.access_token) {
      isAutheticated = true;
    } else {
      isAutheticated = false;
    }

    if (user?.data?.system_role === "admin") {
      isAuthorized = true;
    } else {
      isAuthorized = false;
    }
  } catch (error) {
    isAutheticated = false;
    isAuthorized = false;
  }

  return (
    <React.Fragment>
      {isAutheticated ? (
        isAuthorized ? (
          children
        ) : (
          <Navigate to={"/not-authorized"} />
        )
      ) : (
        <Navigate to={"/login"} />
      )}
    </React.Fragment>
  );
};

export default RouteProtection;
