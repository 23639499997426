import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardActions,
  DialogContent,
  Tooltip,
  DialogActions,
  CircularProgress,
  Stack,
} from "@mui/material";
import { RiAddCircleLine, RiFilter2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CustomizedDialog from "../../Dialog";
import FormRenderer from "./formRenderer";
import { SiGoogleforms } from "react-icons/si";
import { IoMdEye } from "react-icons/io";
import { IoMdCreate } from "react-icons/io";
import { IoMdDocument } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { PAGE_URL } from "../../../../constants/pageUrl.constant";
import Loader from "../../Loader";
import {
  deleteFormById,
  getAllForm,
  getFormTypes,
} from "../../../../redux/slices/forms.slice";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import { DiDatabase } from "react-icons/di";
import FilterDrawer from "../../../layout/filterDrawer";
import DateRangePicker from "../../dateRangePicker";
import Selectt from "react-select";
import dayjs from "dayjs";

const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    // padding: "14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};
const Forms = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [formTypeFilter, setFormTypeFilter] = useState(false);
  const [forms, setForms] = useState([]);
  const [formTypes, setFormTypes] = useState([]);
  const [dateRange_create, setDateRange_create] = useState(["", ""]);
  const [dateRange_update, setDateRange_update] = useState(["", ""]);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchtermForm = (e) => {
    setSearchTerm(e.target.value);
  };

  const fetchAllForms = async () => {
    setLoading(true);
    let custom_filter = {};

    if (formTypeFilter.length > 0) {
      custom_filter.type__in = formTypeFilter.map((item) => item.value);
    }

    if (dateRange_create?.length > 0) {
      custom_filter.created_at__gte =
        dateRange_create[0] == ""
          ? undefined
          : dayjs(dateRange_create[0]).format("YYYY-MM-DD");
      custom_filter.created_at__lte =
        dateRange_create[1] == ""
          ? undefined
          : dayjs(dateRange_create[1]).add(1, "day").format("YYYY-MM-DD");
    }
    if (dateRange_update?.length > 0) {
      custom_filter.updated_at__gte =
        dateRange_update[0] == ""
          ? undefined
          : dayjs(dateRange_update[0]).format("YYYY-MM-DD");
      custom_filter.updated_at__lte =
        dateRange_update[1] == ""
          ? undefined
          : dayjs(dateRange_update[1]).add(1, "day").format("YYYY-MM-DD");
    }
    try {
      const response = await dispatch(
        getAllForm({
          custom_filter: JSON.stringify(custom_filter),
          list: "all",
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setForms(payload?.data);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  const deleteForm = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        deleteFormById({ formId: selectedFormId?.id })
      );
      const { payload } = response;
      if (payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "form deleted succesfully",
          })
        );
        setForms((prev) =>
          prev.filter((item) => item?.id !== selectedFormId?.id)
        );
        setOpenDeleteModal(false);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };
  const fetchAllFormTypes = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormTypes());
      const { payload } = response;
      if (payload?.status) {
        console.log(payload?.data);
        setFormTypes(
          payload?.data?.map((item) => ({ label: item.name, value: item.type }))
        );
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchAllForms();
  }, [dateRange_create, dateRange_update, formTypeFilter]);
  useEffect(() => {
    fetchAllFormTypes();
  }, []);

  return (
    <Box sx={{ paddingBottom: "15px" }}>
      <Box
        variant="div"
        component="div"
        className="space-between media-assitant"
      >
        <Typography sx={{ color: "#00446B !important" }}>
          Create and manage Templates
        </Typography>
        <Box sx={{ display: "flex" }} className="content-header-right">
          <Paper
            elevation={0}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "auto",
              borderRadius: "50px",
              marginRight: "10px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={searchtermForm}
            />
            <IconButton sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine size={20} />}
            disableRipple
            onClick={() => {
              navigate(PAGE_URL.CREATE_FORMS);
            }}
          >
            Create Template
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary-button"
            endIcon={<RiFilter2Line sx={{ "font-size": "22px" }} />}
            disableRipple
            onClick={() => setOpenFilterDrawer(true)}
            style={{ marginLeft: 15, height: "44px" }}
          >
            Filters
          </Button>
        </Box>
      </Box>
      <hr />
      {loading && (
        <Box
          sx={{
            margin: "auto",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ height: "calc(100vh - 190px)", overflowY: "auto" }} p={1}>
        <Grid container spacing={4}>
          {forms.length > 0 ? (
            forms
              ?.filter((item) =>
                item.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((form) => (
                <Grid item xs={12} sm={6} md={4} key={form.id}>
                  <Card
                    sx={{
                      maxHeight: 250,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <CardContent>
                      <Box
                        className="space-between"
                        sx={{
                          paddingBottom: "10px",
                        }}
                      >
                        <IconButton className="New-Chat">
                          <IoMdDocument color="#CA9688" />
                        </IconButton>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title="View Form">
                            <IconButton
                              onClick={() => {
                                setOpenPreview(true);
                                setSelectedFormId(form);
                              }}
                              className="New-Chat"
                            >
                              <IoMdEye color="#CA9688" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit Form">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/settings/formbuilder/${form.type}/${form.id}/edit`
                                )
                              }
                              className="New-Chat"
                            >
                              <IoMdCreate color="#CA9688" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Form">
                            <IconButton
                              onClick={() => {
                                setSelectedFormId(form);
                                setOpenDeleteModal(true);
                              }}
                              className="New-Chat"
                            >
                              <MdDelete color="#CA9688" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          color: "#036185",
                          fontSize: "16px",
                          marginTop: "10px",
                          fontWeight: "700",
                        }}
                        variant="h6"
                      >
                        {form.title}
                      </Typography>
                      <hr style={{ color: "#EBEBEB", opacity: 1 }} />
                      <Typography
                        sx={{ fontSize: "14px", color: "#4F4F4F" }}
                        variant="h6"
                      >
                        {`${
                          form.description.length > 115
                            ? form.description.substring(0, 115) + "..."
                            : form.description
                        }`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
          ) : (
            <Box
              className="no-data-found"
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mt: 1,
              }}
            >
              <Stack spacing={0} justifyContent="center" alignItems="center">
                <DiDatabase size={50} />
                <span>No data found</span>
              </Stack>
            </Box>
          )}
        </Grid>
      </Box>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Preview Form"}
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
      >
        <DialogContent>
          <FormRenderer formId={selectedFormId?.id} />
        </DialogContent>
      </CustomizedDialog>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Delete Confirmation"}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <DialogContent>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              sx={{
                overflowX: "auto",
                whiteSpace: "pre-wrap",
                boxShadow: "none",
              }}
            >
              You want to delete {selectedFormId?.title} ?
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              className="primary-button"
              disabled={loading}
              onClick={deleteForm}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              className="secondary-button"
              disabled={loading}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>

      <FilterDrawer
        open={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
      >
        <Box>
          <Typography variant="body1" component="label">
            Template Type
          </Typography>

          <Selectt
            styles={customStyles}
            isMulti
            name="kbs"
            size="small"
            fullWidth
            className="basic-multi-select"
            options={formTypes}
            value={formTypeFilter}
            onChange={(e) => {
              setFormTypeFilter(e);
            }}
            classNamePrefix="select"
            placeholder={"Select Report Type..."}
            components={{
              IndicatorSeparator: () => null,
            }}
          ></Selectt>
        </Box>
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Created At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_create}
                onDateRangeChange={(e) => setDateRange_create(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
        <Box className="joiningdate">
          <Typography variant="body1" component="label">
            Updated At
          </Typography>
          <Grid item xs={3}>
            <Box>
              <DateRangePicker
                dateRange={dateRange_update}
                onDateRangeChange={(e) => setDateRange_update(e)}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </Box>
          </Grid>
        </Box>
      </FilterDrawer>
    </Box>
  );
};

export default Forms;
